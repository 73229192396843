import { LinearProgress } from "@mui/material";
import { BsStarFill } from "react-icons/bs";

const ProgressBar = () => {
  const totalRate = 160;
  const progressData = [
    {
      rate: 5,
      numOfrate: 70,
    },
    {
      rate: 4,
      numOfrate: 10,
    },
    {
      rate: 3,
      numOfrate: 10,
    },
    {
      rate: 2,
      numOfrate: 25,
    },
    {
      rate: 1,
      numOfrate: 35,
    },
  ];
  return (
    <>
    {progressData.map((data, index) => {
        return (
          <div className="d-flex align-items-center gap-2" key={index}>
            <div
              className="d-flex justify-content-end align-items-center gap-2"
              style={{ width: "50px" }}
            >
              <span>{data?.rate}</span> <BsStarFill color={"var(--yellow)"} />
            </div>
            <div className="d-flex my-2" style={{ width: "100%" }}>
              <LinearProgress
                variant="determinate"
                color="info"
                value={(data.numOfrate / totalRate) * 100}
                style={{
                  height: 10,
                  borderRadius: 10,
                  opacity: 0.6,
                  width: "100%",
                }}
              />
            </div>
            <p
              className="d-flex justify-content-start m-0"
              style={{ color: "#9B9FA3", width: "50px" }}
            >
              ({data.numOfrate})
            </p>
          </div>
        );
      })}
    </>
      
  );
};

export default ProgressBar;
