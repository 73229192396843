import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import Paper from "@mui/material/Paper"
import InputBase from "@mui/material/InputBase"
import IconButton from "@mui/material/IconButton"
import { GoSearch } from "react-icons/go"
import { getCategories } from "../../redux/reducers/categories"
import { useDispatch, useSelector } from "react-redux"
import { getCompanies } from "../../redux/reducers/companies"
import { useLocation } from "react-router"
import { getProducts, setFilter } from "../../redux/reducers/products"
import { getbrands } from "../../redux/reducers/brands"
import Select from "react-select"

function valuetext(value) {
	return `${value}°C`
}

export default function Filter({ setFilter, filter, page }) {
	const category = useSelector(
		(state) => state?.categories?.categories?.results
	)
	const brands = useSelector((state) => state?.brands.brands?.results)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getCategories())
		dispatch(getCompanies())
		dispatch(getbrands())
		setFilter({ ...filter, price: [0, 10000] })
	}, [])

	const handleSliderChange = (event, newValue) => {
		setFilter({ ...filter, [event.target.name]: newValue })
	}

	let subcategoriesData = []
	let sameCategoryAsPage = false
	const categoryData = category?.map((category, index) => {
		if (
			page?.category?.active &&
			category?.title?.localeCompare(page?.category?.value, undefined, {
				sensitivity: "base",
			}) === 0
		) {
			sameCategoryAsPage = true
			category?.subcategories?.map((gory) =>
				subcategoriesData.push({ label: gory?.title, value: gory?.id })
			)
		} else {
			!page?.category?.active &&
				!sameCategoryAsPage &&
				category?.subcategories?.map((gory) =>
					subcategoriesData.push({ label: gory?.title, value: gory?.id })
				)
		}
		return {
			label: category?.slug,
			value: category?.id,
		}
	})

	const brandData = brands?.map((brand) => ({
		label: brand.slug,
		value: brand.id,
	}))

	return (
		<div
			className='p-4 rounded-3'
			style={{ backgroundColor: "rgba(42, 111, 152, 0.15)", width: "100%" }}
		>
			<h2 className='fs-3 fw-bolder text-center py-3'>Filter</h2>
			<div className='d-flex flex-wrap justify-content-center gap-2'>
				<button
					className='text-white border-0  px-2 py-2 rounded-4'
					style={{
						backgroundColor: "var(--blue)",
						fontSize: "14px",
						width: "128px",
					}}
					onClick={() => setFilter({ ...filter, submit: true })}
				>
					Apply Filters
				</button>
				<button
					className='bg-transparent   px-2 py-2 rounded-4'
					style={{
						border: "1px solid var(--blue)",
						color: "var(--blue)",
						fontSize: "14px",
						width: "128px",
					}}
				>
					Reset Filters
				</button>
			</div>

			<p className='fw-bold fs-5 py-3 mb-0'>Price Range</p>
			<Box sx={{ width: "100%" }}>
				<p className='m-0 small' style={{ color: "var(--blue)" }}>
					${filter?.price?.[0]} - ${filter?.price?.[1]}
				</p>
				<Slider
					getAriaLabel={() => "Price range"}
					value={filter?.price}
					name='price'
					onChange={handleSliderChange}
					valueLabelDisplay='auto'
					color='primary'
					max={10000}
					defaultValue={[0, 0]}
				/>
			</Box>
			{!page?.category?.active && !page?.sub_category && (
				<>
					<p className='fw-bold fs-5 pt-3 mb-0'>Category</p>

					<div className='d-flex justify-content-center flex-wrap gap-2 py-3 w-100 '>
						<Select
							className='w-100'
							closeMenuOnSelect={false}
							placeholder='Select Categories'
							isMulti
							onChange={(values) =>
								setFilter({
									...filter,
									category: values?.map((value) => value.label),
								})
							}
							options={categoryData}
						/>
					</div>
				</>
			)}
			{!page?.sub_category && (
				<>
					<p className='fw-bold fs-5 pt-3 mb-0'>Sub-Category</p>
					<div className='d-flex justify-content-center flex-wrap gap-2 py-3'>
						<Select
							className='w-100'
							style={{ border: 0 }}
							closeMenuOnSelect={false}
							placeholder='Select Subcategories'
							isMulti
							options={subcategoriesData}
							onChange={(values) =>
								setFilter({
									...filter,
									sub_category: values?.map((value) => value.label),
								})
							}
						/>
					</div>
				</>
			)}
			{!page?.brand && (
				<>
					<p className='fw-bold fs-5 pt-3 mb-0'>Brand</p>

					<div className='d-flex justify-content-center flex-wrap gap-2 py-3'>
						<Select
							className='w-100'
							style={{ border: 0 }}
							closeMenuOnSelect={false}
							placeholder='Select Brands'
							isMulti
							options={brandData}
							onChange={(values) =>
								setFilter({
									...filter,
									brand: values?.map((value) => value?.label),
								})
							}
						/>
					</div>
				</>
			)}
		</div>
	)
}
