import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReviews } from "../../../../redux/reducers/reviews";
import ReviewsList from "./ReviewsList/ReviewsList";

const MyReviews = () => {
  const dispatch = useDispatch()
	useEffect(() => {
	  dispatch(getReviews())
	}, [])
	const reviews = useSelector((state) => state?.reviews?.reviews?.results)
  return (
    <div>
      {/* <TextField
        className="w-100"
        id="input-with-icon-textfield"
        placeholder="Search for a review"
        hiddenLabel
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        variant="standard"
      /> */}
      <div className="py-4 reviews-list">
        <ReviewsList reviews={reviews} />
      </div>
    </div>
  );
};

export default MyReviews;
