import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCompanyPages } from "../../../redux/reducers/companies"
import { getProducts } from "../../../redux/reducers/products"
import SupplierHome from "./SupplierHome/SupplierHome"
import SupplierAboutUs from "./SupplierAboutUs/SupplierAboutUs"
import SupplierContactUs from "./SupplierContactUs/SupplierContactUs"

const SupplierStoreTaps = (props) => {
	const { company } = props
	const supplierTaps = ["Home", "About Us", "Contact Us"]
	const [activeTab, setActiveTab] = useState(0)
	const dispatch = useDispatch()
	const companyProducts = useSelector((state) => state?.products?.products)
	const companyPages = useSelector((state) => state?.companies?.companyPages)

	useEffect(() => {
		dispatch(getProducts({ company: company?.id }))
		dispatch(getCompanyPages(company?.id))
	}, [company])

	return (
		<>
			<div className='d-flex gap-5' style={{ overflow: "auto" }}>
				<div
					className=' text-light gap-2 d-flex align-items-center'
					style={{ minWidth: "550px" }}
				>
					{supplierTaps.map((tab, index) => {
						return (
							<p
								className='p-0 m-0 p-3 px-5 active-tab'
								key={index}
								style={{
									backgroundColor: activeTab === index ? "#fff" : "#01497C",
									color: activeTab === index ? "#01497C" : "#fff",
									fontWeight: "bold",
								}}
								onClick={() => setActiveTab(index)}
							>
								{tab}
							</p>
						)
					})}
				</div>
			</div>
			<div className='tabs-view'>
				{activeTab === 0 && <SupplierHome companyProducts={companyProducts} />}
				{activeTab === 1 && (
					<SupplierAboutUs companyPages={companyPages} company={company} />
				)}
				{activeTab === 2 && <SupplierContactUs company={company} />}
			</div>
		</>
	)
}

export default SupplierStoreTaps
