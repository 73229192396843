import React, { useEffect } from "react";
import OrderItem from "./OrderItem";

const OrderItems = (props) => {
  const { variation, item } = props;

  return (
    <>
      {item?.items?.map((item) => (
        <div style={{ overflow: "auto" }}>
          <OrderItem variation={variation} item={item} />
        </div>
      ))}
      {item?.whole_sale?.map((item) => (
        <div style={{ overflow: "auto" }}>
          <OrderItem variation={variation} item={item} />
        </div>
      ))}
    </>
  );
};

export default OrderItems;
