import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../redux/reducers/products";
import ItemCard from "../ItemCard/ItemCard";
import WholesaleCard from "../WholesaleCard/WholesaleCard";

export default function CategoryCards(props) {
  const { data } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [currentSubCategory, setCurrentSubCategory] = useState(null);
  const dispatch = useDispatch();
  const products = useSelector(
    (state) => state.products.productsFiltered?.results
  );
  const ploading = useSelector((state) => state.products?.ploading);
  const loading = useSelector((state) => state.products.loading);

  useEffect(() => {
    currentSubCategory &&
      dispatch(getProducts({ category: currentSubCategory?.slug }));
  }, [currentSubCategory]);

  useEffect(() => {
    setCurrentSubCategory(data?.subcategories?.[0]);
    data && setActiveTab(0);
  }, [data]);

  return (
    <div className="row  rounded-3 p-0 my-5">
      <div className="col-lg-3 d-flex flex-column justify-content-evenly fw-bold align-items-center gap-3 ">
        <div className="bg-light w-100 h-100 p-3 rounded-3">
          <p className="fw-bold fs-5" style={{ color: "var(--blue)" }}>
            Sub-Categories
          </p>
          {data == undefined ? (
            <div className=" d-flex flex-column gap-4 justify-content-center">
              {Array(3)
                .fill(0)
                .map(() => (
                  <li>
                    <Skeleton variant="text" width={258.01} height={23.98} />
                  </li>
                ))}
            </div>
          ) : (
            data?.subcategories?.map((subCategory, index) => {
              return (
                <p
                  role="button"
                  className={activeTab === index ? "fw-bold" : "fw-normal"}
                  style={{
                    color: "var(--blue)",
                  }}
                  onClick={() => {
                    setActiveTab(index);
                    setCurrentSubCategory(subCategory);
                  }}
                  key={index}
                >
                  {subCategory?.title}
                </p>
              );
            })
          )}
        </div>
      </div>
      <div className="col-lg-9 d-flex flex-wrap align-items-center flex-lg-row flex-column gap-4">
        {products == undefined ? (
          <div className=" d-flex gap-4 justify-content-center ">
            {Array(3)
              .fill(0)
              .map(() => (
                <li>
                  <Skeleton variant="rectangular" width={290} height={342} />
                </li>
              ))}
          </div>
        ) : (
          products
            ?.slice(0, 3)
            ?.map((item, index) =>
              item?.item?.length > 0 ? (
                <WholesaleCard data={item} key={index} />
              ) : (
                <ItemCard data={item} key={index} />
              )
            )
        )}
        {products?.length === 0 && (
          <p className="text-center fw-bold w-100">No Products Found</p>
        )}
      </div>
    </div>
  );
}
