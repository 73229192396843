const CertificationsCard = ({ item }) => {
  return (
    <div
      className="col-md-5 p-4 rounded-3"
      style={{ background: "rgba(42, 111, 152, 0.15)" }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <p className="cer-title">{item?.title}</p>
        <p className="bg-light px-md-4 py-md-2 p-2 rounded-pill text-center exp-title">{item?.expires}</p>
      </div>
      <div>
        <p className="cer-comp" style={{ color: "#6C757D" }}>
          {item?.company}
        </p>
        <button
          className="btn text-light mt-4 px-4"
          style={{ backgroundColor: "#01497C" }}
        >
          View Certificate
        </button>
      </div>
    </div>
  );
};

export default CertificationsCard;
