import CartCard from "./CartCard";

const CartList = ({products, wholesale}) => {

  return (
    <>
      {products?.map((item, index) => (
        <CartCard key={index} item={item} wholesale={wholesale}/>
      ))}
    </>
  );
};

export default CartList;
