const CustomBadge = ({number}) => {
    return(
        <div
        className="bg-danger text-white rounded-3 py-1 px-2 m-0"
        style={{
          position: "relative",
          bottom: 10,
          right: 5,
        }}
      >
        <p className="m-0 p-0">{number}</p>
      </div>
    )
}

export default CustomBadge