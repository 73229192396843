import React, { useEffect, useState } from "react"
import Banner from "../../components/Banner/Banner"
import img from "../../assets/img/OrderPageTopBanner.png"
import { Container } from "react-bootstrap"
import SearchBar from "../../components/SearchBar/SearchBar"
import OrdersCard from "../../components/Orders/OrdersCard/OrdersCard"
import Dropdown from "../../components/Dropdown/Dropdown"
import { useDispatch, useSelector } from "react-redux"
import { getOrders } from "../../redux/reducers/orders"
import { Skeleton } from "@mui/material";

const OrdersPage = () => {
	//state
	const [search, setSearch] = useState("")
	const [searchEntered, setSearchEntered] = useState("")
	const [sort, setSort] = useState("")

	//redux
	const dispatch = useDispatch()
	const ordersList = useSelector(state => state?.orders?.ordersList?.results)
	const loading = useSelector((state) => state?.orders?.loading);

	useEffect(() => {
		dispatch(getOrders({find: searchEntered, sort: sort}))
	},[dispatch, sort ,searchEntered])

	return (
		<Container>
			<Banner title='Orders' img={img} />
			<div className='mt-md-0 mt-5 d-flex'>
				<SearchBar onChange={(e)=> setSearch(e.target.value)} onKeyDown={(e) => {
					// console.log(e.key)
					e.key === 'Enter' && setSearchEntered(e.target.value)
				}} value={search}/>
				<Dropdown placeholder='Sort By' sort={true} setSort={setSort}/>
			</div>
			{loading ? (
        <div className=" d-flex flex-column gap-4 justify-content-center my-5">
          {Array(3)
            .fill(0)
            .map(() => (
              <li>
                <Skeleton variant="rectangular" height={40} />
              </li>
            ))}
        </div>
      ) : (
        ordersList?.map((item, index) => <OrdersCard item={item} key={index} />)
      )}
				{
					ordersList?.length === 0 && 
					<div className="rounded-3 d-flex justify-content-center align-items-center" style={{
						height: 200,
					}}>
					<h1 className='text-center'>No Orders Found</h1>
					</div>
				}
		</Container>
	)
}

export default OrdersPage;
