
const ProductSpecific = ({item}) => {
  return (
    <div className="row d-flex align-items-center my-3">
      <div className="modal-title col-3">
        <b className="text-light">{item?.title}</b>
      </div>
      <div className='modal-body d-flex align-items-center col-5 mx-3 px-3' style={{
        background: item?.title === 'Colours' ? 'rgba(0, 0, 0,0)' : 'rgba(42, 111, 152, 0.15)',
        boxShadow: item?.title === 'Colours' && 'none'
      }}>
        {
          item?.title === 'Colours' ?
          item?.body?.map((color, index)=>{
            return(
              <p style={{
                backgroundColor: color,
                height: '40px',
                width: '40px',
                borderRadius: 10,
                margin:"0",
                marginRight: "5px"
              }} key={index}></p>
            )
          })
          :
          <p className="m-0">{item.body}</p>
        }
      </div>
    </div>
  );
};

export default ProductSpecific;
