import CertificationsCard from "./CertificationsCard";

const CertificationsSlider = () => {
  const certifications = [
    {
      title: "Certificate Name1",
      company: "Certifying Company1",
      expires: "expires on 30/11/2022",
    },
    {
      title: "Certificate Name2",
      company: "Certifying Company2",
      expires: "expires on 31/11/2022",
    },
    {
      title: "Certificate Name3",
      company: "Certifying Company3",
      expires: "expires on 30/11/2022",
    },
    {
      title: "Certificate Name4",
      company: "Certifying Company4",
      expires: "expires on 31/11/2022",
    }
  ];
  return (
    <>
      <div className="px-2 row gap-3 d-flex justify-content-center align-items-center">
      <h3 className="title-details my-md-5 my-3">Certifications</h3>

        {certifications.map((item, index) => {
          return <CertificationsCard item={item} key={index} />;
        })}
      </div>
    </>
  );
};

export default CertificationsSlider;
