import ProfilePic from "../../assets/common/profile.png"
import Banner from "../../components/Banner/Banner"
import "./Profile.css"
import ProfileDetails from "./ProfileDetails/ProfileDetails"
import ProfileInfo from "./ProfileInfo/ProfileIfo"

const Profile = () => {
	return (
		<div className='container mt-2  '>
			<div className='cont d-flex flex-column'>
				<Banner title='Profile' img={ProfilePic} />
				<div className='row d-flex '>
					<div className='col-lg-3 p-0'>
						<ProfileInfo />
					</div>
					<div className='col-lg-9'>
						<ProfileDetails />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Profile
