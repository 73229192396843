import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { BASE_URL } from "../common"

const token = localStorage.getItem("token")
	? localStorage.getItem("token")
	: null

export const getReviews = createAsyncThunk(
	"reviews/get",
	async (args, { rejectWithValue }) => {
		try {
			const config = {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			}
			const response = await axios.get(
				`${BASE_URL}/company/purchase/reviews/?expand=company,product`,
				config
			)
			return response.data
		} catch (error) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data)
			} else {
				return rejectWithValue(error.message)
			}
		}
	}
)
export const EditReview = createAsyncThunk(
	"reviews/patch",
	async (data, { rejectWithValue }) => {
		try {
			const config = {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			}
			const response = await axios.patch(
				`${BASE_URL}/company/purchase/reviews/${data?.id}/?expand=company,product`,
				data,
				config
			)
			return response.data
		} catch (error) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data)
			} else {
				return rejectWithValue(error.message)
			}
		}
	}
)
export const DeleteReviews = createAsyncThunk(
	"reviews/patch",
	async ({ data }, { rejectWithValue }) => {
		try {
			const config = {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			}
			const response = await axios.patch(
				`${BASE_URL}/company/purchase/reviews/${data?.id}/?expand=company,product`,
				{ active: data?.active },
				config
			)
			return response.data
		} catch (error) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data)
			} else {
				return rejectWithValue(error.message)
			}
		}
	}
)
const initialState = {
	loading: false,
	reviews: null,
	token: token,
	error: false,
}

const reviewsSlice = createSlice({
	name: "reviews",
	initialState,

	extraReducers: {
		// 	reviews
		[getReviews.pending]: (state, action) => {
			state.loading = true
			state.error = false
		},
		[getReviews.fulfilled]: (state, action) => {
			state.loading = false
			state.reviews = action.payload
			state.error = false
		},
		[getReviews.rejected]: (state, action) => {
			state.loading = false
			state.error = action.payload
		},
	},
})

export default reviewsSlice.reducer
