import { useState } from "react";
import { BsStarFill } from "react-icons/bs";

const StarsRate = ({ num, size, clicked, setClickedStar }) => {
  const [StarNumber, setStarNumber] = useState( num)
  const stars = [1, 2, 3, 4, 5];
  return (
    <>
      {stars?.map((star, index) => {
        return (
          <BsStarFill
            color={index < StarNumber ? "var(--yellow)" : "var(--lightgrey)"}
            size={size ? size : 20}
            key={index}
            className="mx-1"
            onClick={()=>{
              if(clicked){
                setStarNumber(index + 1)
                setClickedStar(index+1)
              }
            }}
          />
        );
      })}
    </>
  );
};

export default StarsRate;
