import React, { useEffect, useState } from "react";
import img from "../../assets/img/ProductImage1.png";
import { BsStarFill } from "react-icons/bs";
import { GoHeart } from "react-icons/go";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  decreaseQuantity,
  increaseQuantity,
} from "../../redux/reducers/cart";
import { toast } from "react-toastify";
import {
  createFavorite,
  deleteFavorite,
  getFavorites,
} from "../../redux/reducers/favorites";

export default function ItemCard(props) {
  const data = props;
  // console.log(data)
  const item = data?.data?.sku_product ? data?.data?.sku_product : data?.data;
  const starArray = [...Array(5).keys()].map((i) => i + 1);
  const [fav, setFav] = useState(item?.favorite && item?.favorite);
  const [quantity, setQuantity] = useState(0);
  // const favHandler = () => setFav(!fav)
  // console.log(item);

  const sku = data?.data?.skus?.[0];
  const unit = data?.data?.unit === "unit" ? "" : `per ${data?.data?.unit}`;

  //dispatch
  const dispatch = useDispatch();
  //selector
  const cart = useSelector((state) => state?.cart?.productsCart);

  //functions
  const HandleAddToCart = (e) => {
    e.preventDefault();
    toast.success("Added Successfuly", {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
    dispatch(
      addToCart({
        id: item?.id,
        title: item?.title,
        stock: item?.skus?.[0]?.stock,
        slug: item?.slug,
        price: item?.price,
        unit: item?.unit,
        company: item?.company,
        gallery: data?.data?.images,
        tag: item?.tag,
        sku: item?.skus?.[0],
        currency: item?.price_currency,
        type: "products",
      })
    );
  };

  const HandleIncreaseQuantity = (e) => {
    e.preventDefault();
    dispatch(increaseQuantity({ id: item?.id, stock: item?.skus?.[0]?.stock }));
  };
  const HandleDecreaseQuantity = (e) => {
    e.preventDefault();
    dispatch(decreaseQuantity({ id: item?.id }));
  };

  const AddToFav = (e) => {
	e.preventDefault()
    if (fav) {
      //condition favorite status for item
	  setFav(false);
      dispatch(deleteFavorite({
		id: item?.id,
		type: "product",
	  })).then((res) => {
        if (res.meta.requestStatus == "fulfilled") {
          toast.error("removed Successfuly", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
          dispatch(getFavorites());
        }
      });
    } else {
		setFav(true);
      dispatch(
        createFavorite({
          product: item?.id,
        })
      ).then((res) => {
        if (res.meta.requestStatus == "fulfilled") {
          toast.success("Added Successfuly", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
          dispatch(getFavorites());
        }
      });
    }
  };

  useEffect(() => {
    const itemInCart = cart?.find((cartItem) => cartItem?.id === item?.id);
    itemInCart ? setQuantity(itemInCart?.quantity) : setQuantity(0);
  }, [cart]);

  return (
    <div
      className="p-3 shadow border-0 rounded position-relative "
      style={{
        border: "0.1px solid #D6D6D6",
        height: data?.data?.sku_product ? "280px" : "342px",
        width: "290px",
      }}
    >
      <p
        className="m-0 notSelectable position-absolute small py-1 px-3 rounded-end text-white"
        style={{
          top: "25px",
          left: "0",
          zIndex: "2",
          backgroundColor: data?.data?.tag?.[0]?.color
            ? data?.data?.tag?.[0]?.color
            : item?.tag?.[0]?.color,
        }}
      >
        {data?.data?.tag?.[0]?.title
          ? data?.data?.tag?.[0]?.title
          : item?.tag?.[0]?.title}
      </p>
      <div className="position-relative">
        <img
          src={
            data?.data?.images?.[0]?.image
              ? data?.data?.images?.[0]?.image
              : item?.csv
              ? item?.images?.[0]?.image
                ? item?.images?.[0]?.image
                : item?.gallery?.[0]?.image
                ? item?.gallery?.[0]?.image
                : img
              : img
          }
          unselectable="on"
          className="w-100 notSelectable"
          alt="product"
          style={{
            height: "180px",
            objectFit: "contain",
          }}
        />
        <p
          className="m-0 position-absolute notSelectable small py-1 px-3 rounded-pill bg-white shadow-sm"
          style={{ color: "var(--blue)", bottom: "10px", left: "7px" }}
        >
          {data?.data?.sku_product ? data?.data?.stock : sku?.stock}{" "}
          {data?.data?.unit && data?.data?.unit !== "unit"
            ? data?.data?.unit
            : item?.unit && item?.unit !== "unit"
            ? item?.unit
            : ""}{" "}
          available
        </p>

        <p
          className="text-decoration-none m-0"
          onClick={(e) => AddToFav(e)}
          style={{ cursor: "pointer" }}
        >
          <GoHeart
            className="fs-3 position-absolute"
            style={{
              top: "10px",
              right: "10px",
              color: (fav) ? "var(--purple)" : "white",
            }}
          />
        </p>
      </div>
      <Link
        to={
          data?.data?.sku_product
            ? `/product/wholesale/${item?.slug}`
            : `/product/${item?.slug}`
        }
        className="card-body text-decoration-none d-flex flex-column"
      >
        <p
          className="m-0 notSelectable fs-4 p-0 fw-bold pt-3 pb-2"
          style={{ color: "var(--black)", lineHeight: "1em" }}
        >
          {item?.title?.slice(0, 15)}
          {item?.title?.length >= 15 && "..."}
        </p>
        <p className="m-0 small notSelectable" style={{ color: "var(--blue)" }}>
          {item?.company?.company_name}
        </p>
        {!data?.data?.sku_product && (
          <span className="my-1">
            {starArray.map((i, index) => (
              <BsStarFill
                key={index}
                className="me-1"
                style={{
                  color: item?.rating_avg >= i ? "#FFD700" : "#D0D5DB",
                }}
              />
            ))}
          </span>
        )}
        <div className="d-flex justify-content-between align-items-center">
          {!data?.data?.sku_product && (
            <p className="m-0 small notSelectable">
              {sku?.price_currency} {Number(sku?.price)} {unit}
            </p>
          )}

          {quantity === 0 ? (
            !data?.data?.sku_product && (
              <Button
                onClick={(e) => HandleAddToCart(e)}
                className="position-absolute btn text-decoration-none small rounded-3 px-4 py-2 text-white ms-auto"
                style={{
                  backgroundColor: "var(--blue)",
                  width: "fit-content",
                  bottom: "15px",
                  right: "10px",
                }}
              >
                Add to cart
              </Button>
            )
          ) : (
            <>
              <div
                className="d-flex justify-content-center align-items-center gap-3 ms-auto"
                onClick={(e) => e.preventDefault()}
              >
                <Button
                  className="btn text-decoration-none"
                  style={{
                    backgroundColor: "var(--blue)",
                  }}
                  onClick={(e) => HandleDecreaseQuantity(e)}
                >
                  -
                </Button>
                <p className="text-center position-relative p-0 m-0">
                  {quantity}
                </p>
                <Button
                  className="btn text-decoration-none"
                  style={{
                    backgroundColor: "var(--blue)",
                  }}
                  onClick={(e) => HandleIncreaseQuantity(e)}
                >
                  +
                </Button>
              </div>
            </>
          )}
        </div>
      </Link>
    </div>
  );
}
