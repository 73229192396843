import React from "react"
import Banner from "../../components/Banner/Banner"

import img2 from "../../assets/Cart/Cart Product Image.png"
import { CgCloseR } from "react-icons/cg"
import { MdKeyboardArrowDown } from "react-icons/md"
import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material"
import { BsBoxSeam } from "react-icons/bs"
import { IoIosArrowDown } from "react-icons/io"

function Finance() {
	const data = [
		{
			title: "Highgrad Cement",
			body: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore quidem corporis dolorem quia veritatis similique.",
			company_name: "Company Name",
			rating: 3,
			price: 500,
			units: "tons",
			stock: "3 tons available",
			status: "new",
		},
		{
			title: "Highgradeea Cement",
			body: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore quidem corporis dolorem quia veritatis similique.",
			company_name: "Company Name",
			rating: 3,
			price: 500,
			units: "tons",
			stock: "3 tons available",
			status: "new",
		},
		{
			title: "one",
			body: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore quidem corporis dolorem quia veritatis similique.",
			company_name: "euroasian",
			rating: 4,
			price: 500,
			units: "tons",
			stock: "3 tons available",
			status: "used",
		},
		{
			title: "aaawwww",
			body: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore quidem corporis dolorem quia veritatis similique.",
			company_name: "euroasian",
			rating: 4,
			price: 500,
			units: "tons",
			stock: "3 tons available",
			status: "reduced",
		},
		{
			title: "Highgrade Cement",
			body: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore quidem corporis dolorem quia veritatis similique.",
			company_name: "Company Name",
			rating: 3,
			price: 500,
			units: "tons",
			stock: "6 products total",
			status: "Wholesale",
		},
		{
			title: "theee",
			body: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore quidem corporis dolorem quia veritatis similique.",
			company_name: "euroasian",
			rating: 4,
			price: 500,
			units: "tons",
			stock: "6 products total",
			status: "Wholesale",
		},
		{
			title: "theee",
			body: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore quidem corporis dolorem quia veritatis similique.",
			company_name: "euroasian",
			rating: 4,
			price: 500,
			units: "tons",
			stock: "6 products total",
			status: "Wholesale",
		},
		{
			title: "theee",
			body: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore quidem corporis dolorem quia veritatis similique.",
			company_name: "euroasian",
			rating: 4,
			price: 500,
			units: "tons",
			stock: "6 products total",
			status: "Wholesale",
		},
	]
	return (
		<div className='container'>
			<Banner title='Finance' />
			<div className='row align-items-start'>
				<div className='col-5'>
					{" "}
					<h1
						className='text-nowrap'
						style={{
							height: " 48px",
							fontWeight: 700,
							fontSize: "32px",
							lineHeight: "48px",
							color: "rgba(1, 59, 99, 1)",
						}}
					>
						Fill in your Request
					</h1>
				</div>
				<div className='col-7 p-0 ps-4 d-lg-flex d-none'>
					<h1
						style={{
							height: " 48px",
							fontWeight: 600,
							fontSize: "32px",
							lineHeight: "48px",
							color: "rgba(1, 59, 99, 1)",
						}}
					>
						Requested Products
					</h1>
				</div>
			</div>
			<div className='row align-items-start'>
				<div className='col-lg-5'>
					<div className='col-12'>
						<FormControl
							variant='outlined'
							style={{
								width: "295px",
								margin: "15px 0",
							}}
						>
							<InputLabel htmlFor='outlined-adornment-email'>
								Product Name
							</InputLabel>
							<OutlinedInput
								required
								defaultValue=''
								id='outlined-adornment-email'
								type='email'
								endAdornment={
									<InputAdornment position='end'>
										<IconButton edge='end'>
											{/* <CiUser
                          style={{
                            color: "rgba(1, 73, 124, 1)",
                          }}
                        /> */}
										</IconButton>
									</InputAdornment>
								}
								label='Product Name'
							/>
						</FormControl>
					</div>
					<div className='row'>
						<div className='col-8'>
							<FormControl
								variant='outlined'
								style={{
									width: "100%",
									margin: "15px 0",
								}}
							>
								<InputLabel htmlFor='outlined-adornment-email'>
									Tonnes
								</InputLabel>
								<OutlinedInput
									required
									defaultValue=''
									id='outlined-adornment-email'
									type='email'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton edge='end'>
												<IoIosArrowDown
													style={{
														color: "rgba(1, 73, 124, 1)",
													}}
												/>
											</IconButton>
										</InputAdornment>
									}
									label='Tonnes'
								/>
							</FormControl>
						</div>
						<div className='col-3'>
							<FormControl
								variant='outlined'
								style={{
									width: "100%",
									margin: "15px 0",
								}}
							>
								<InputLabel htmlFor='outlined-adornment-email'>
									Tonnes
								</InputLabel>
								<OutlinedInput
									required
									defaultValue=''
									id='outlined-adornment-email'
									type='email'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton edge='end'>
												<IoIosArrowDown
													style={{
														color: "rgba(1, 73, 124, 1)",
													}}
												/>
											</IconButton>
										</InputAdornment>
									}
									label='Tonnes'
								/>
							</FormControl>
						</div>
					</div>
					<div className='col-12'>
						<FormControl
							variant='outlined'
							style={{
								width: "295px",
								margin: "15px 0",
							}}
						>
							<InputLabel htmlFor='outlined-adornment-email'>Tonnes</InputLabel>
							<OutlinedInput
								required
								defaultValue=''
								id='outlined-adornment-email'
								type='email'
								endAdornment={
									<InputAdornment position='end'>
										<IconButton edge='end'>
											<IoIosArrowDown
												style={{
													color: "rgba(1, 73, 124, 1)",
												}}
											/>
										</IconButton>
									</InputAdornment>
								}
								label='Tonnes'
							/>
						</FormControl>
					</div>
					<div className='col-12'>
						<FormControl
							variant='outlined'
							style={{
								width: "100%",
								margin: "15px 0",
							}}
						>
							<InputLabel htmlFor='outlined-adornment-email'>
								Why are you applying for financial aid?
							</InputLabel>
							<OutlinedInput
								required
								defaultValue=''
								id='outlined-adornment-email'
								type='email'
								endAdornment={
									<InputAdornment position='end'>
										<IconButton edge='end'>
											{/* <CiUser
                          style={{
                            color: "rgba(1, 73, 124, 1)",
                          }}
                        /> */}
										</IconButton>
									</InputAdornment>
								}
								label='Why are you applying for financial aid?'
							/>
						</FormControl>
					</div>
					<div className='col-12'>
						<button
							class='btn btn-primary px-5 py-3 mt-3'
							type='submit'
							style={{
								backgroundColor: "#01497C",
								width: "100%",
								fontweight: 600,
								fontSize: "16px",
								lineHeight: "24px",
							}}
						>
							Request Finance
							<span className='ms-2'>
								<BsBoxSeam />
							</span>
						</button>
					</div>
				</div>

				<div className='col-lg-7 p-0 ps-4'>
					{data.map((item) => (
						<div
							class='card mb-3 shadow p-2 mb-3 bg-body rounded border-1'
							style={{ maxWidth: "710px", borderColor: "#ececec" }}
						>
							<div class='row g-0'>
								<div
									class='col-md-4'
									style={{
										width: "fit-content",
										padding: "6px",
									}}
								>
									<img src={img2} class='img-fluid rounded-start' alt='...' />
								</div>
								<div class='col-md-8 d-flex justify-content-center align-items-center'>
									<div class='card-body p-0 ps-3'>
										<div className='d-flex justify-content-between align-items-start mb-3'>
											<div>
												<h5
													class='card-title'
													style={{
														width: "244px",
														height: " 32px",
														fontWeight: 700,
														fontSize: "24px",
														lineHeight: "32px",
														color: "#012A4A",
													}}
												>
													Highgrade Cement
												</h5>
												<p
													class='card-text'
													style={{
														width: "104px",
														height: " 16px",
														fontWeight: 400,
														fontSize: "12px",
														lineHeight: "16px",
														color: "#6C757D",
													}}
												>
													Company Name
												</p>
											</div>
											<div className=''>
												<CgCloseR
													style={{
														fontSize: "20px",
													}}
												/>
											</div>
										</div>
										<div>
											<div className='d-flex justify-content-between align-items-end'>
												<div>
													<h5 class='card-title'>
														<div
															className='px-2 py-2'
															style={{
																backgroundColor: "rgba(42, 111, 152, 0.15)",
															}}
														>
															<p
																className='p-0 m-0'
																style={{
																	width: "54px",
																	height: " 16px",
																	fontWeight: 400,
																	fontSize: "12px",
																	lineHeight: "16px",
																	color: "#012A4A",
																}}
															>
																Quantity
															</p>
															<div className='d-flex align-items-center'>
																<p
																	className='p-0 m-0'
																	style={{
																		width: "37px",
																		height: " 16px",
																		fontWeight: 500,
																		fontSize: "12px",
																		lineHeight: "16px",
																		letterSpacing: "0.5px",
																		color: "#62A5C2",
																	}}
																>
																	1 tons
																</p>
																<MdKeyboardArrowDown
																	style={{
																		width: "6.44pxpx",
																		height: " 16px",
																		fontWeight: 500,
																		fontSize: "12px",
																		lineHeight: "16px",
																		letterSpacing: "0.5px",
																		color: "#62A5C2",
																	}}
																/>
															</div>
														</div>
													</h5>
												</div>
												<div
													style={{
														fontWeight: 600,
														fontsize: "22px",
														lineHeight: "33px",
														color: "#62A5C2",
													}}
												>
													$500
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default Finance
