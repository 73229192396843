import React, { useEffect, useState } from "react";
import Filter from "../../components/Filter/Filter";
import img from "../../assets/img/ProductImage1.png";
import ItemCard from "../../components/ItemCard/ItemCard";
import WholesaleCard from "../../components/WholesaleCard/WholesaleCard";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../../components/Banner/Banner";
import reducedImage from "../../assets/img/Reduced.png";
import mostPopularImage from "../../assets/img/MostPopular.png";
import NewArrivalsImage from "../../assets/img/NewArrivals.png";
import TopRatedImage from "../../assets/img/TopRated.png";
import NewEqImage from "../../assets/img/NewEq.png";
import UsedEqImage from "../../assets/img/UsedEq.png";
import Dropdown from "../../components/Dropdown/Dropdown";
import { useDispatch } from "react-redux";
import { getProducts } from "../../redux/reducers/products";
import { useLocation, useParams } from "react-router";
import { Skeleton } from "@mui/material";
import Pagination from "../../components/Pagination/Pagination";

const DetailsTemplate = ({ pageName, search, sort, tag }) => {
  const dispatch = useDispatch();
  const params = useParams();
  // console.log(params);
  const location = useLocation();
  const sub_category_page = location?.pathname?.includes("/sub_category/");
  const category_page = location?.pathname?.includes("/category/");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortText, setSort] = useState(sort);
  // const [dispatchParams, setDispatchParams]= useState(null)
  const [filter, setFilter] = useState({
    submit: false,
    price: [0, 10000],
    category: [],
  });
  const [products, setProducts] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);

  // const deSlugify = (slug) => slug?.replace("-", " ")

  useEffect(() => {
    // console.log("beginning dispatch");
    const { category, brand, search } = params;
    // console.log(category);

    let dispatchParams = {
      find: search,
      category: category,
      brand: brand,
      sort: sortText,
      tag: tag,
      pageNumber: pageNumber,
    };
    // console.log(200, dispatchParams);
    setLoading(true);
    getProductsFromAPI(dispatchParams);
  }, [params, sortText, tag, pageNumber]);

  useEffect(() => {
    const { category, brand, search } = params;
    let dispatchParams = {
      find: search,
      category: category,
      brand: brand,
      sort: sortText,
      tag: tag,
      pageNumber: pageNumber,
    };
    filter.submit && (dispatchParams = { ...dispatchParams, ...filter });
    setLoading(true);
    filter.submit && getProductsFromAPI(dispatchParams);
    filter.submit && setFilter({ ...filter, submit: false });
  }, [filter.submit]);

  const getProductsFromAPI = (dispatchParams) => {
    // console.log(200, dispatchParams);
    dispatch(getProducts(dispatchParams)).then((res) => {
      // console.log(res);
      if (res.meta.requestStatus === "fulfilled") {
        setProducts(res.payload.data.results);
        setCount(res.payload.data.count);
        setLoading(false);
      }
    });
  };

  const currentBanner = (text) => pageName === text;

  const bannerImg = currentBanner("Reduced")
    ? reducedImage
    : currentBanner("Most Popular")
    ? mostPopularImage
    : currentBanner("Top Rated")
    ? TopRatedImage
    : currentBanner("New Arrivals")
    ? NewArrivalsImage
    : currentBanner("New Equipment")
    ? NewEqImage
    : currentBanner("Used Equipment")
    ? UsedEqImage
    : img;

  const skeleton = Array(9)
    .fill(0)
    .map((a, index) => (
      <Skeleton
        key={index}
        variant="rounded"
        width={290}
        height={342}
        animation="wave"
      />
    ));

  return (
    <Container className="px-md-5 px-3 ">
      {pageName && (
        <Banner
          title={
            params.brand
              ? params.brand.replace("-", " ").toUpperCase()
              : pageName
          }
          img={
            tag !== "" && products?.[0]?.tag?.image
              ? products?.[0]?.tag?.image
              : bannerImg
          }
        />
      )}
      <Row className="mt-5">
        <Col md={9}>
          {search && (
            <p className=" fs-2 fw-bold m-0" style={{ color: "var(--mdBlue)" }}>
              Search Results for{" "}
              <span style={{ color: "#B71C1C" }}>
                "{params?.search?.replace("-", " ")}"
              </span>
            </p>
          )}
          <p className="small p-0 m-0 text-muted fw-bold">
            Showing {count} results, across {Math.round(count / 10)} pages
          </p>
        </Col>
        {search && (
          <Dropdown placeholder="Sort By" sort={true} setSort={setSort} />
        )}
      </Row>
      <Row>
        <div className="col-lg-9">
          <div
            className="d-flex gap-3 justify-content-md-start justify-content-center flex-wrap  my-3"
            style={{ height: "fit-content" }}
          >
            {loading
              ? skeleton
              : products?.map((item, index) =>
                  item?.item?.length > 0 ? (
                    <WholesaleCard key1={index} data={item} />
                  ) : (
                    <ItemCard key1={index} data={item} />
                  )
                )}
          </div>

		<Pagination count={count} setPageNumber={setPageNumber} pageNumber={pageNumber} />
        </div>

        <div className="col-lg-3 my-3 p-0 d-flex flex-column gap-4 justify-content-start">
          {!search && (
            <Dropdown placeholder="Sort By" sort={true} setSort={setSort} />
          )}
          <Filter
            page={{
              category: { active: category_page, value: params.category },
              sub_category: sub_category_page,
            }}
            setFilter={setFilter}
            filter={filter}
          />
        </div>
      </Row>
    </Container>
  );
};

export default DetailsTemplate;
