import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getOrderStatus } from "../../../redux/reducers/orders";
import Line from "../../Lines/VerticalLine";
import TrackStage from "./TrackStage";

const AllStages = (props) => {
  const { current, horizontal, type, status } = props;
  //states
  const [stages, setStages] = useState([]);
  //redux
  const dispatch = useDispatch()
	const orderStatus = useSelector(state => state?.orders?.orderStatus?.results)
  const cancel = current == orderStatus?.[orderStatus?.length - 1]?.id

	useEffect(()=>{
		dispatch(getOrderStatus())
	},[dispatch])

  useEffect(()=>{
  let array = [];
    type == "order" ? array = orderStatus?.slice() : type == "finance" ? array = [
      { id: 1, status: "Requested" },
      { id: 2, status: "Required Files" },
      { id: 3, status: "In consideration" },
      { id: 4, status: `${status == 'Accepted' ? 'Accepted' : status == 'Done' ? 'Done' : 'Rejected'}` },
    ] : array = []
    setStages(array)
  },[orderStatus, status])
  // type === "order"
  //   ? orderStatus?.slice()
  //   : type === "finance"
  //   ? (array = [
  //     { id: 1, status: "Requested" },
  //     { id: 2, status: "Required Files" },
  //     { id: 3, status: "In consideration" },
  //     { id: 4, status: "Accepted" },
  //   ])
  //   : (array = []);
  return (
    <>
      {stages?.map((item, index) => {
        const currentItem = item?.id;
        const currentIndex = index + 1;
        return (
          <>
            <div className={horizontal && "col"}>
              <TrackStage
              status={status}
                item={item}
                horizontal={horizontal}
                type={type}
                stage={currentItem}
                currentIndex={currentIndex}
                success={currentItem < current}
                done={currentItem < current}
                current={currentItem === current}
              />
              {!horizontal && currentIndex !== stages?.length && <Line />}
            </div>
            {horizontal && currentIndex !== stages?.length && (
              <Col md="auto" className="d-flex align-items-center">
                <Line horizontal={horizontal} />
              </Col>
            )}
          </>
        );
      })}
    </>
  );
};

export default AllStages;
