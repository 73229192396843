import { useState } from "react"
import SearchBar from "../../../../components/SearchBar/SearchBar"

const UserManual = () => {
	//states
	const [selectedProduct, setSelectedProduct] = useState(0)

	//dummy data
	const productOverView = [
		{
			title: "Product Overview1",
			details: [
				{
					section: "Section One",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
				{
					section: "Section Two",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
				{
					section: "Section Three",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
				{
					section: "Section Four",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
			],
		},
		{
			title: "Product Overview2",
			details: [
				{
					section: "Section One2",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
				{
					section: "Section Two2",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
				{
					section: "Section Three2",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
				{
					section: "Section Four2",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
			],
		},
		{
			title: "Product Overview3",
			details: [
				{
					section: "Section One3",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
				{
					section: "Section Two3",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
				{
					section: "Section Three3",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
				{
					section: "Section Four3",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
			],
		},
		{
			title: "Product Overview4",
			details: [
				{
					section: "Section One4",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
				{
					section: "Section Two4",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
				{
					section: "Section Three4",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
				{
					section: "Section Four",
					body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales ",
				},
			],
		},
	]
	return (
		<div className='row'>
			<p className='title-details py-3'>User Manual</p>
			<div className='col-md-4 mb-md-0 mb-3'>
				<SearchBar notshadow={true} />
				<div
					className='p-3 my-3 rounded-4 '
					style={{
						backgroundColor: "#F8F9FA",
						height: "200px",
						overflowY: "auto",
					}}
				>
					{productOverView.map((item, index) => {
						return (
							<p
								className='m-2 pro-over-title'
								key={index}
								onClick={() => setSelectedProduct(index)}
								style={{
									color: index === selectedProduct ? "#01497C" : "#012A4A",
									fontWeight: index === selectedProduct ? "bold" : "normal",
								}}
							>
								{index + 1}. {item.title}
							</p>
						)
					})}
				</div>
			</div>
			<div className='col-md-8 mb-md-0 mb-3 over-details'>
				<p
					className='pro-over-title mb-3'
					style={{ fontWeight: "normal", fontSize: 28 }}
				>
					{selectedProduct + 1}. {productOverView[selectedProduct].title}
				</p>
				{productOverView[selectedProduct].details.map((item, index) => {
					return (
						<div className='px-3' key={index}>
							<p
								style={{
									fontWeight: 700,
									fontSize: 16,
									color: "var(--mdBlue)",
								}}
							>
								{item?.section}
							</p>
							<p className='m-3'>{item?.body}</p>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default UserManual
