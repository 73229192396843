import React from "react"
import { Link } from "react-router-dom"

const CurrencyDropdown = () => {
	return (
		<>
			<div className='dropdown'>
				<button
					className='btn p-0 fsSmall d-flex gap-2 align-items-center text-white border-0 dropdown-toggle'
					type='button'
					id='dropdownMenuButton1'
					data-bs-toggle='dropdown'
					aria-expanded='false'
				>
					USD $
				</button>
				<ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
					<li>
						<Link className='dropdown-item' to=''>
							USD
						</Link>
					</li>
					<li>
						<Link className='dropdown-item' to=''>
							AED
						</Link>
					</li>
					<li>
						<Link className='dropdown-item' to=''>
							EGP
						</Link>
					</li>
				</ul>
			</div>
		</>
	)
}

export default CurrencyDropdown
