import { useState } from "react";
import Details from "./Details/Details";
import "../ProductPage";
import Certifications from "./Certifications/Certifications";
import UserManual from "./UserManual/UserManual";
import Variants from "./Variants/Variants";
import Reviews from "./Reviews/Reviews";

const ProductDetailsTaps = (props) => {
  const product = props.product
  //states
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    "Details",
    "Certifications",
    "User Manual",
    "Variants",
    "Reviews",
  ];

  return (
    <>
      <div className="tab-container mt-5">
        <div style={{ overflow: "auto" }}>
        <div
          className="d-flex gap-2 justify-content-between align-items-center"
          style={{minWidth:"1000px"}}
        >
          {tabs.map((test, index) => {
            return (
              <p
                className="m-0 tab"
                style={{
                  color: activeTab === index ? "var(--blue)" : "#fff",
                  backgroundColor: activeTab === index ? "#fff" : "var(--blue)",
                }}
                onClick={() => setActiveTab(index)}
                key={index}
              >
                {test}
              </p>
            );
          })}
        </div>
        </div>
       
        <div className="option-box mx-3 p-md-5 p-0">
          {activeTab === 0 && <Details />}
          {activeTab === 1 && <Certifications />}
          {activeTab === 2 && <UserManual />}
          {activeTab === 3 && <Variants product={product.variant} />}
          {activeTab === 4 && <Reviews />}
        </div>
      </div>
    </>
  );
};

export default ProductDetailsTaps;
