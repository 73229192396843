import React, { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { TfiArrowCircleLeft } from "react-icons/tfi"
import { useNavigate } from "react-router"
import { STYLING } from "../../components/Orders/OrdersCard/styling"
const BackButton = (props) => {
	const { text, backLink } = props
	useEffect(() => {
		if (backLink === undefined)
			throw Error(
				"component: BackButton | No backlink found, Please enter a backlink "
			)
	}, [backLink])

	const navigate = useNavigate()

	return (
		<Row>
			<Col>
				<div
					role='button'
					onClick={() => (backLink ? navigate(backLink) : navigate(-1))}
				>
					<p
						className='d-inline d-flex align-items-center'
						style={{
							fontSize: STYLING.font.size.medium,
							color: STYLING.colors.primary,
						}}
					>
						<TfiArrowCircleLeft
							size='1.2em'
							className='me-2'
							color={STYLING.colors.primary}
						/>
						{text === undefined && "Go "}Back {text && "to"}
						<span
							className='mx-1'
							style={{
								color: STYLING.colors.light,
							}}
						>
							{text}
						</span>
					</p>
				</div>
			</Col>
		</Row>
	)
}

export default BackButton
