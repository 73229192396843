import { LocationOnOutlined } from "@mui/icons-material"
import { useState } from "react"
import Addresses from "../../../components/Addresses/Addresses"
import AddressForm from "../../../components/Addresses/AddressForm"
import "../Settings.css"

const ShippingAddress = () => {
	const [openAddressForm, setOpenAddressForm] = useState(false)

	const HandleOpenAddressForm = () => {
		setOpenAddressForm(!openAddressForm)
	}

	return (
		<div className='mt-4'>
			<div className='d-flex justify-content-between align-items-center'>
				<p className='personal-title'>Shipping Address</p>
			</div>
			<div className='row my-2'>
				<p className='main-add-title'>Main Address</p>
				<Addresses />
				<div className='w-100'>
					<button
						className='btn text-light p-2 w-100'
						id='add-address-btn'
						onClick={HandleOpenAddressForm}
					>
						{openAddressForm ? "Close the form" : "Add Address"}{" "}
						{!openAddressForm && <LocationOnOutlined />}
					</button>
				</div>
			</div>
			{openAddressForm && (
				<AddressForm setOpenAddressForm={setOpenAddressForm} />
			)}
		</div>
	)
}

export default ShippingAddress
