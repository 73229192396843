import { CgCloseR } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../../redux/reducers/cart";
import CartList from "../cartList/CartList";

const WholeSaleList = ({ item }) => {
    const dispatch = useDispatch();
  const wholesaleCart = useSelector((state) => state.cart?.wholesaleCart);

  const removeItemFromCart = () => {
    dispatch(
        removeFromCart({
            id: item?.id,   
            type: "wholesale"
        })
    )
  }

  return (
    <>
    <div className="border rounded-4 px-3 py-5 my-4">
    <div className="d-flex justify-content-between align-items-center pe-5 "  >
        <h4>{item?.title}</h4>
        <h4>{item?.price}</h4>
        <p>
          <CgCloseR
            style={{
              fontSize: "20px",
              cursor: "pointer",
            }}
            onClick={removeItemFromCart}
          />
        </p>
      </div>
      <div className="my-4">
        <CartList products={wholesaleCart} wholesale={true}/>
      </div>
    </div>
      
    </>
  );
};

export default WholeSaleList;
