import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { GoSearch } from "react-icons/go";
const SearchBar = (props) => {
  const { notshadow, onChange, value, onKeyDown } = props;
  return (
    <InputGroup
      className={
        notshadow
          ? "me-2 me-md-3 border w-100 search-bar rounded-pill"
          : "me-2 me-md-3 shadow w-100 search-bar rounded-pill"
      }
    >
      <InputGroup.Text className="bg-white border-0 px-md-4 " id="basic-addon1">
        <GoSearch size="20px" color="#6C757D" />
      </InputGroup.Text>
      <Form.Control
        className="border-0 shadow-none"
        placeholder="Search for an Order"
        aria-label="Username"
        aria-describedby="basic-addon1"
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
    </InputGroup>
  );
};

export default SearchBar;
