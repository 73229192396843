export const STYLING = {
	colors: {
		primary: "#01497C",
		darkBlue: "#012A4A",
		grey: "#6C757D",
		dark: "#012A4A",
		light: "#62A5C2",
		success: "#7ACC66",
	},
	bgColors: {
		light: "#DFE9F0",
	},
	borders: { colors: { grey: "#D0D5DB" } },
	font: {
		primary: "Poppins",
		bold: 700,
		size: {
			title: "24px",
			column: { title: "20px", subtitle: "12px" },
			medium: "18px",
			small: "8px",
		},
	},
	buttonStyling: {
		backgroundColor: "#01497C",
		fontWeight: "400",
		fontSize: "14px",
		padding: "10px, 24px, 10px, 24px",
		height: "40px",
		width: "129px",
	},
	readyMadeStyling: {
		columnImg: { height: 90, width: 90, objectFit: "cover" },
		columnImgV1: { height: 67, width: 67, objectFit: "cover" },
		columnTitle: {
			fontSize: "20px",
			color: "#012A4A",
		},
		columnTitleV1: {
			fontSize: "15px",
			color: "#012A4A",
		},
		columnSubtitle: {
			fontSize: "12px",
			color: "#6C757D",
		},
		columnSubtitleV1: {
			fontSize: "9px",
			color: "#6C757D",
		},
		columnStatus: { height: "40px", width: "200px" },
		columnStatusV1: { height: "30", width: "129px", fontSize: "10px" },
	},
}
