import StarsRate from "../../../../../components/StarsRate/StarsRate"
import ProgressBar from "./ProgressBar/ProgressBar"

const ReviewsRate = () => {
	return (
		<div
			className='row align-items-center my-4 mx-1'
			style={{
				background: "#FFFFFF",
				border: "0.5px solid #F8F9FA",
				boxShadow: "0px -2px 15px rgba(1, 73, 124, 0.05)",
				borderRadius: "10px 10px 0px 0px",
			}}
		>
			<div
				className='col-md-4 d-flex flex-column justify-content-center align-items-center'
				style={{
					borderRight: "2px solid #E9E9E9",
				}}
			>
				<p
					style={{
						fontSize: 64,
						fontWeight: 700,
						color: "var(--darkBlue)",
					}}
				>
					4.0
				</p>
				<div>
					<StarsRate num={4} size={35} />
				</div>
				<p
					className='my-3'
					style={{
						fontSize: 12,
						fontWeight: 400,
						color: "#6C757D",
					}}
				>
					based on 158 reviews
				</p>
			</div>
			<div className='col-md-8 mt-2'>
				<ProgressBar />
			</div>
		</div>
	)
}

export default ReviewsRate
