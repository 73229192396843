import ReviewPeople from "./ReviewPeople/ReviewPeople";
import ReviewsRate from "./ReviewsRate/ReviewsRate";

const Reviews = () => {
  return (
    <div>
      <p className="title-details my-3">Reviews</p>
      <ReviewsRate />
      <ReviewPeople />
    </div>
  );
};

export default Reviews;
