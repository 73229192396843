import React, { useEffect, useState } from 'react';
import img from '../../assets/img/ProductImage1.png';
import { GoHeart } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../redux/reducers/cart';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  createFavorite,
  deleteFavorite,
  getFavorites,
} from '../../redux/reducers/favorites';

export default function WholesaleCard(props) {
  const data = props;
  const [quantity, setQuantity] = useState(0);
  const productsCount = data?.data?.item?.length;
  const item = data?.data;
  const [fav, setFav] = useState(item?.favorite && item?.favorite);

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart?.wholesaleCart);

  //functions
  const HandleAddToCart = (e) => {
    e.preventDefault();
    toast.success('Added Successfuly', {
      position: 'bottom-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'light',
    });
    dispatch(
      addToCart({
        id: item?.id,
        title: item?.title,
        stock: item?.item?.length,
        slug: item?.slug,
        price: item?.price,
        company: item?.company,
        items: item?.item,
        type: 'wholesale',
      })
    );
  };

  const AddToFav = () => {
    if (fav) {
      //delete
      setFav(false);
      dispatch(
        deleteFavorite({
          id: item?.id,
          type: 'wholesale',
        })
      ).then((res) => {
        if (res.meta.requestStatus == 'fulfilled') {
          toast.error('removed Successfuly', {
            position: 'bottom-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
          });
          dispatch(getFavorites());
        }
      });
    } else {
      //add
      setFav(true);
      dispatch(
        createFavorite({
          wholesale: item?.id,
        })
      ).then((res) => {
        if (res.meta.requestStatus == 'fulfilled') {
          toast.success('Added Successfuly', {
            position: 'bottom-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
          });
          dispatch(getFavorites());
        }
      });
    }
  };
  // console.log(item);

  useEffect(() => {
    const itemInCart = cart?.find((cartItem) => cartItem?.id === item?.id);
    itemInCart ? setQuantity(itemInCart?.quantity) : setQuantity(0);
  }, [cart]);
  return (
    <div
      key={props.key1}
      className="p-3 border-0 shadow rounded position-relative "
      style={{
        border: '0.1px solid #D6D6D6',
        height: '342px',
        width: '290px',
      }}>
      <p
        className="m-0 position-absolute notSelectable small py-1 px-3 rounded-end text-white"
        style={{
          top: '25px',
          left: '0',
          zIndex: '2',
          backgroundColor:
            data?.data?.item?.length > 0 ? 'var(--purple)' : 'var(--orange)',
        }}>
        {data?.data?.item?.length > 0 ? 'Wholesale' : ''}
      </p>
      <div className="position-relative ">
        <div
          className="row px-2 notSelectable  d-flex"
          style={{
            height: '180px',
          }}>
          {data?.data?.item?.map((item) => (
            <Col md={6} key={props.key1} style={{ padding: '2px' }}>
              <img
                src={item?.images?.[0]?.image}
                unselectable="on"
                className="w-100 p-0 notSelectable"
                style={{ height: '60px' }}
                alt="img"
              />
            </Col>
          ))}
          {/* {Array(productsCount)
            .fill(0)
            .map((item, index) => (
              <div key={index} className="col-6 p-0 ">
                <div style={{ padding: "2px" }}>
                  <img
                    src={img}
                    unselectable="on"
                    className="w-100 p-0 notSelectable"
                    style={{ height: "60px" }}
                    alt="img"
                  />
                </div>
              </div>
            ))} */}
        </div>
        <p
          className="m-0 notSelectable position-absolute small py-1 px-3 rounded-pill bg-white shadow-sm"
          style={{ color: 'var(--blue)', bottom: '10px', left: '7px' }}>
          {data?.data?.item?.length} products total
        </p>

        <p
          className="text-decoration-none m-0  "
          onClick={AddToFav}
          style={{ cursor: 'pointer' }}>
          <GoHeart
            className="fs-3 position-absolute"
            style={{
              top: '10px',
              right: '10px',
              color: fav ? 'var(--purple)' : 'white',
            }}
          />
        </p>
      </div>
      <div className="card-body d-flex flex-column">
        <Link
          to={`/wholesale/${data?.data?.id}`}
          className="text-decoration-none">
          <p
            className="m-0 fs-4 p-0 fw-bold pt-3 pb-2 notSelectable"
            style={{ color: 'var(--black)', lineHeight: '1em' }}>
            {data?.data?.title}
          </p>
          <p
            className="m-0 small notSelectable"
            style={{ color: 'var(--blue)' }}>
            {data?.data?.company}
          </p>
        </Link>

        <Button
          onClick={HandleAddToCart}
          className="btn position-absolute text-decoration-none small rounded-3 px-4 py-2 text-white ms-auto"
          style={{
            backgroundColor: 'var(--blue)',
            width: 'fit-content',
            bottom: '15px',
            right: '10px',
          }}
          disabled={quantity > 0 ? true : false}>
          {quantity > 0 ? 'Added Successfuly' : 'Add to cart'}
        </Button>
      </div>
    </div>
  );
}
