import React, { useState } from "react"
import img1 from "../../../assets/auth/Category Banner.png"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import Input from "@mui/material/Input"
import FilledInput from "@mui/material/FilledInput"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import InputAdornment from "@mui/material/InputAdornment"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { useNavigate, useParams } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import Joi from "joi"
import { resetpassword } from "../../../redux/reducers/auth"
import ClipLoader from "react-spinners/ClipLoader"
function ResetPassword() {
	const { uid, token } = useParams()

	const [showPassword, setShowPassword] = React.useState(false)

	const handleClickShowPassword = () => setShowPassword((show) => !show)

	const handleMouseDownPassword = (event) => {
		event.preventDefault()
	}

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const loading = useSelector((state) => state.auth.loading)

	// useState

	const [resetPasswordConfirm, setResetPasswordConfirm] = useState({
		uid,
		token,
		new_password: "",
	})
	const [err, setErr] = useState([])
	const [errBack, setErrBack] = useState([])

	// Catch errors to display next to input field

	function getCurrentErr(key) {
		for (const e of err) {
			if (e.context.key === key) {
				return e.message
			}
		}
		return ""
	}

	// Store data in the usestate

	const onchange = (e) => {
		setResetPasswordConfirm({
			...resetPasswordConfirm,
			[e.target.name]: e.target.value,
		})

		//filter the current error only

		let filteredErrors = []
		filteredErrors = err?.filter((k) => k?.path[0] !== e.target.name)
		setErr(filteredErrors)
		setErrBack("")
	}

	// submit to done or get errors

	const onSubmit = (e) => {
		e.preventDefault()
		let schema = Joi.object({
			uid,
			token,
			new_password: Joi.string().required().label("Password"),
		})

		let joiResponse = schema.validate(resetPasswordConfirm, {
			abortEarly: false,
		})

		if (joiResponse.error) {
			setErr(joiResponse.error.details)
		} else {
			try {
				dispatch(resetpassword({ ...resetPasswordConfirm })).then((res) => {
					if (res.meta.requestStatus !== "rejected") {
						navigate("/")
					} else {
						// setErr(res.payload.detail);
						setErrBack(res.payload)
					}
				})
			} catch (error) {}
		}
	}

  return (
    <div className="container-fluid p-0 m-0">
      <div className="row p-0 m-0">
        <div className="col-md-6 p-0 m-0">
          <div className="position-relative">
            <img
              className="w-100 position-relative"
              src={img1}
              alt="img1"
              style={{
                height: "100vh",
              }}
            />
            <h1
              className="position-absolute font_A"
              style={{
                maxWidth: "176px",
                height: "95px",
                left: "54.42px",
                top: "36px",
                fontWeight: 400,
                fontSize: "47.5px",
                lineHeight: "95px",
                color: "#FFFFFF",
                letterSpacing: "5.2px",
              }}
            >
              Surplus
            </h1>
          </div>
        </div>
        <div className="col-6 d-flex  flex-column justify-content-center align-items-center">
          <h1
            className="font"
            style={{
              fontWeight: 600,
              fontSize: "48px",
              lineHeight: "72px",
              color: "#183A59",
              maxWidth: "521px",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Enter your Password
          </h1>
          <p
            style={{
              color: "rgba(98, 165, 194, 1)",
              textDecoration: "none",
              fontWeight: 600,
              fontSize: "24px",
              lineHeight: "36px%",
              width: "697px",
              textAlign: "center",
            }}
          >
            Your New Password Must Be Different From Your
          </p>
          <p
            style={{
              color: "rgba(98, 165, 194, 1)",
              textDecoration: "none",
              fontWeight: 600,
              fontSize: "20.9279px",
              lineHeight: "120%",
            }}
          >
            {" "}
            Previous Password
          </p>
          <div>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <FormControl variant="outlined" className="mb-4 mt-4">
                  <InputLabel htmlFor="outlined-adornment-password">
                    {getCurrentErr("new_password")
                      ? getCurrentErr("new_password") &&
                        "You must write a valid new_password "
                      : errBack.length === 0
                      ? "Password"
                      : errBack}{" "}
                  </InputLabel>
                  <OutlinedInput
                    error={
                      getCurrentErr("new_password") || errBack.length !== 0
                        ? true
                        : false
                    }
                    onChange={onchange}
                    name="new_password"
                    style={{
                      maxWidth: "438px",
                      borderRadius: "5px",
                    }}
                    id="outlined-adornment-new_password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff
                              style={{
                                color: "rgba(230, 230, 230, 1)",
                              }}
                            />
                          ) : (
                            <Visibility
                              style={{
                                color: "rgba(230, 230, 230, 1)",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={
                      getCurrentErr("new_password")
                        ? getCurrentErr("new_password") &&
                          "You must write a valid new_password "
                        : errBack.length === 0
                        ? "new_password"
                        : errBack
                    }
                  />
                </FormControl>
                <FormControl variant="outlined" className="mb-4">
                  <InputLabel htmlFor="outlined-adornment-password">
                    {getCurrentErr("Re-Password")
                      ? getCurrentErr("Re-Password") &&
                        "You must write a valid Password "
                      : errBack.length === 0
                      ? "Re-Password"
                      : errBack}
                  </InputLabel>
                  <OutlinedInput
                    error={
                      getCurrentErr("Re-Password") || errBack.length !== 0
                        ? true
                        : false
                    }
                    style={{
                      maxWidth: "438px",
                      borderRadius: "5px",
                    }}
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff
                              style={{
                                color: "rgba(230, 230, 230, 1)",
                              }}
                            />
                          ) : (
                            <Visibility
                              style={{
                                color: "rgba(230, 230, 230, 1)",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={
                      getCurrentErr("Re-Password")
                        ? getCurrentErr("Re-Password") &&
                          "You must write a valid password "
                        : errBack.length === 0
                        ? "Re-Password"
                        : errBack
                    }
                  />
                </FormControl>
                <button
                  onClick={onSubmit}
                  style={{
                    maxWidth: "438px",
                    background: "rgba(1, 73, 124, 1)",
                    color: "#fff",
                    height: "60px",
                    padding: "0px 20.9279px",
                    fontWeight: 700,
                    fontSize: "20.9279px",
                    lineHeight: "120%",
                    borderRadius: "10px",
                    border: "none",
                  }}
                  type="submit"
                >
                  {loading ? (
                    <ClipLoader height="10" color="#36d7b7" />
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword
