const Address = ({ address }) => {
  // const data = address.company;
  return (
    <div className="address-box">
      <div className="row country-city d-flex justify-content-center align-items-center">
        <p className="col-6">Country: {address?.country}</p>
        <p className="col-6">City: {address?.city}</p>
      </div>
      <p>Address: {address?.address_name}</p>
    </div>
  );
};

export default Address;
