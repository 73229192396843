import { height } from "@mui/system"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProducts } from "../../redux/reducers/products"
import img from "../../assets/img/ProductImage1.png"
import { Card, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import slugify from "react-slugify"
import { Skeleton } from "@mui/material"

export default function CategoryPage(props) {
	const { data,loading } = props
	const [activeTab, setActiveTab] = useState(0)
	const [currentSubCategory, setCurrentSubCategory] = useState(null)

	const dispatch = useDispatch()
	const products = useSelector(
		(state) => state.products.productsFiltered?.results
	)

	useEffect(() => {
		dispatch(getProducts({ category: currentSubCategory?.title }))
	}, [currentSubCategory])

	useEffect(() => {
		setCurrentSubCategory(data?.subcategories?.[0])
	}, [data])

	
	const skeleton = Array(6)
    .fill(0)
    .map(() => (
		<Col lg='4' md="6"  className='mb-4 d-flex justify-content-center p-0'>
      <Skeleton variant="rounded" width={277} height={337} animation="wave" />
	  </Col>
    ));
	return (
		<Row className='row justify-content-center rounded-3'>
			{loading ? <Skeleton variant="rounded" width={"100%"} height={50} className="mb-3" animation="wave" /> :
					<div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
					
					<p
						className='fw-bold m-0'
						style={{
							color: "#01497c",
							fontSize: "25px",
						}}
					>
						{data?.title}
					</p>
					<Link
					to={`/category/${slugify(data?.title.toLowerCase())}`}
					className="btn py-1 px-4 rounded-pill text-white"
					style={{ backgroundColor: "var(--purple)" }}
				  >
					View More
				  </Link>
				</div>
				}
				
				<Row className="d-flex flex-wrap justify-content-lg-start justify-content-center">
					{loading ? skeleton : data?.subcategories?.map((subCategory, index) => {
						return (
							<Col lg='4' md="6"  className='mb-4 d-flex justify-content-center p-0'>
								<Link className="text-decoration-none" to={`/category/${slugify(subCategory?.title.toLowerCase())}`}>
								<Card
									style={{
										maxWidth: "277px",
										width:"100%",
										height: "337px",
									}}
								>
									<Card.Img
										variant='top'
										className='pt-3 px-4'
										style={{
											maxWidth: "276px",
											width:"100%",
											height: "258px",
										}}
										src={subCategory?.image ? subCategory?.image : img}
										alt=''
										// className='w-100 rounded-1'
									/>
									<Card.Body>
										<Card.Title
											className='text-center p-0'
											style={{
												fontWeight: 600,
												fontSize: "20px",
												lineHeight: "33px",
												color: "#01497c",
											}}
										>
											{subCategory?.title}
										</Card.Title>
									</Card.Body>
								</Card>
							</Link>
							</Col>
							
						)
					})}
				</Row>
		</Row>
	)
}
