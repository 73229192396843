import AdditionalCard from "./AdditionalCard"

const AdditionalSet = () => {
	const additionSetData = [
		{
			title: "Change Password ",
			description:
				"Have you forgotten your password, or want to change your password for better security? please click the button on the right to change it ",
			btnClick: () => {},
			icon: "password",
		},
	]
	return (
		<div>
			<p className='personal-title'>Additional Settings</p>
			{additionSetData.map((data, index) => {
				return <AdditionalCard key={index} item={data} />
			})}
		</div>
	)
}

export default AdditionalSet
