import { Skeleton } from "@mui/material";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Header from "./Header";
import OrderItems from "./OrderItems";
import OrderSummary from "./OrderSummary";

const OrdersCard = (props) => {
  const { variation, item, outsideHeader } = props;
  const loading = useSelector((state) => state?.orders?.loading);

  return (
    <Row>
      <Col>
        {loading ? (
          <div className=" d-flex flex-column gap-4 justify-content-center">
            {Array(1)
              .fill(0)
              .map(() => (
                <li>
                  <Skeleton variant="text" width={258.01} height={50} />
                </li>
              ))}
          </div>
        ) : (
          outsideHeader && <Header variation={variation} item={item} />
        )}
        <Card
          style={{ borderColor: "#ececec" }}
          className="p-md-4 p-3 mb-4 mt-2 shadow rounded"
        >
          {loading ? (
            <div className=" d-flex flex-column gap-4 justify-content-center">
              {Array(1)
                .fill(0)
                .map(() => (
                  <li>
                    <Skeleton variant="text" width={258.01} height={50} />
                  </li>
                ))}
            </div>
          ) : (
            !outsideHeader && <Header item={item} />
          )}
          {loading ? (
            <div className=" d-flex flex-column gap-4 justify-content-center my-3">
              {Array(2)
                .fill(0)
                .map(() => (
                  <li>
                    <Skeleton variant="rectangular" height={50} />
                  </li>
                ))}
            </div>
          ) : (
            <OrderItems variation={variation} item={item} />
          )}
          {loading ? (
            <div className=" d-flex flex-column gap-4 justify-content-center my-3">
              {Array(1)
                .fill(0)
                .map(() => (
                  <li>
                    <Skeleton variant="rectangular" height={50} />
                  </li>
                ))}
            </div>
          ) : (
            <OrderSummary variation={variation} item={item} />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default OrdersCard;
