import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import img from "../../assets/img/OrderPageTopBanner.png";
import Banner from "../../components/Banner/Banner";
import BackButton from "../../components/BackButton/BackButton";
import OrdersCard from "../../components/Orders/OrdersCard/OrdersCard";
import TrackOrder from "../../components/Orders/TrackOrder/TrackOrder";
import Addresses from "../../components/Addresses/Addresses";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "../../redux/reducers/orders";
import { useParams } from "react-router";
import Address from "../../components/Addresses/Adsress";
import { Skeleton } from "@mui/material";

const OrderPage = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const order = useSelector((state) => state?.orders?.order);
  const loading = useSelector((state) => state?.orders?.loading);

  useEffect(() => {
    dispatch(getOrder(id));
  }, [id]);

  return (
    <Container>
      <Banner title="Your Order Details" img={img} />

      {loading ? (
        <div className=" d-flex flex-column gap-4 justify-content-center">
          {Array(1)
            .fill(0)
            .map(() => (
              <li>
                <Skeleton variant="text" width={258.01} height={23.98} />
              </li>
            ))}
        </div>
      ) : (
        <BackButton text="All Orders" backLink="/orders" />
      )}
      <Row>
        <Col md={9}>
          {<OrdersCard item={order} outsideHeader={true} variation={1} />}
        </Col>
        <Col className="pt-4 mt-5">
          <TrackOrder order={order} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h3
            style={{
              color: "#2A6F98",
              fontWeight: "bold",
            }}
            className="my-2 mb-4"
          >
            Shipping Address
          </h3>
          {loading ? (
            <div className=" d-flex flex-column gap-4 justify-content-center my-3">
              {Array(1)
                .fill(0)
                .map(() => (
                  <li>
                    <Skeleton
                      variant="rectangular"
                      width={636.02}
                      height={119.97}
                    />
                  </li>
                ))}
            </div>
          ) : (
            <Address address={order?.shipping_address} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default OrderPage;
