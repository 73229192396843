import { useState } from "react";
import { useDispatch } from "react-redux";
import { DeleteReviews, getReviews } from "../../../../../redux/reducers/reviews";
import ReviewCard from "../../../../ProductPage/ProductDetailsTabs/Reviews/ReviewPeople/ReviewCard";
import "../../../Profile.css";

const ReviewsList = (props) => {
  const { reviews } = props;
  const dispatch = useDispatch();
  const [edit,setEdit] = useState(false)
  const handleEdit = () => {
    setEdit(!edit)
  }
  return (
    <>
      {reviews?.map((review, index) => {
         return (
          <>
          {

          review?.active === true &&  
            <div className="mb-4" key={index}>
              <p
                style={{
                  color: "#01497C",
                  fontWeight: "bold",
                  fontSize: 18,
                }}
              >
                Your review on “
                <span
                  style={{
                    textDecoration: "underline",
                    color: "var(--lightBlue)",
                  }}
                >
                  {review?.product?.title}
                </span>
                ”
              </p>
              <ReviewCard review={review} edit={edit} />
              <div className="d-flex gap-2 mt-3">
                <button onClick={handleEdit} className="btn px-4 py-2 rounded-3 edit-btn">
                  Edit
                </button>
                <button
                  className="btn px-4 py-2 rounded-3 delete-btn"
                  onClick={() =>
                    dispatch(
                      DeleteReviews({ data: { active: false, id: review?.id } })
                    ).then((res)=>{
                      dispatch(getReviews())
                    })
                  }
                >
                  Delete
                </button>
              </div>
            </div>
          }

            </>
          ) 
        }
      )}
      {reviews?.length === 0 && (
          <div
            style={{
              height: 500,
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <p className="text-center fw-bold">No Reviews Found</p>
          </div>
        )}
    </>
  );
};

export default ReviewsList;
