import React from "react"
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5"
import { GiDiscGolfBag } from "react-icons/gi"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Banner from "../../components/Banner/Banner"
function DoneOrder() {
	const orderDone = useSelector((state) => state?.orders?.orderCreated)

	return (
		<div className='container'>
			<Banner title='Order Done' />{" "}
			<div className='card mb-3 shadow bg-body rounded border-1'>
				<div className='row p-5 d-flex justify-content-center align-items-center'>
					<div className='col-4'>
						<IoCheckmarkDoneCircleOutline
							style={{
								fontSize: "400px",
							}}
						/>
					</div>
					<div className='col-8'>
						<div className='d-flex justify-content-center align-items-start flex-column w-100'>
							<p
								className='p-0 m-0'
								style={{
									fontWeight: 600,
									fontSize: "22px",
									lineHeight: "33px",
									color: "#012A4A",
								}}
							>
								Your order has been
							</p>
							<p
								className='p-0 m-0'
								style={{
									fontWeight: 600,
									fontSize: "22px",
									lineHeight: "33px",
									color: "#012A4A",
								}}
							>
								submitted for approval
							</p>
						</div>
						<h1
							className='py-4 font_A'
							style={{
								fontWeight: 400,
								fontSize: "57px",
								lineHeight: "64px",
								color: "#2A6F98",
							}}
						>
							Order {orderDone?.order_no}
						</h1>
						<div className='d-flex justify-content-start align-items-center w-100 gap-3'>
							<Link
								class='btn btn-primary py-3 px-5 d-flex justify-content-center align-items-center'
								type='submit'
								style={{
									backgroundColor: "#01497C",
									fontweight: 600,
									fontSize: "16px",
									lineHeight: "24px",
									borderRadius: "10px",
								}}
								to='/'
							>
								Back to Shopping
								<span className='ms-2'>
									<GiDiscGolfBag />
								</span>
							</Link>
							<Link
								class='btn btn-primary py-3 px-5 d-flex justify-content-center align-items-center'
								type='submit'
								style={{
									backgroundColor: "#01497C",
									fontweight: 600,
									fontSize: "16px",
									lineHeight: "24px",
									borderRadius: "10px",
								}}
								to='/orders'
							>
								Go to Orders
								<span className='ms-2'>
									<GiDiscGolfBag />
								</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DoneOrder
