import { configureStore } from "@reduxjs/toolkit"
import auth from "./reducers/auth"
import Companies from "./reducers/companies"
import products from "./reducers/products"
import categories from "./reducers/categories"
import brands from "./reducers/brands"
import cart from "./reducers/cart"
import orders from "./reducers/orders"
import addresses from "./reducers/addresses"
import reviews from "./reducers/reviews"
import finances from "./reducers/finances"
import favorites from './reducers/favorites'

export const store = configureStore({
	reducer: {
		auth: auth,
		companies: Companies,
		categories: categories,
		products: products,
		brands: brands,
		cart: cart,
		orders: orders,
		addresses: addresses,
		reviews: reviews,
		finances: finances,
		favorites: favorites
	},
})
