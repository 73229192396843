import React, { useState } from "react";
import img1 from "../../../assets/auth/Category Banner.png";
import Box from "@mui/material/Box";
import {
  Checkbox,
  FormControl,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { SlEnvolope } from "react-icons/sl";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CiUser } from "react-icons/ci";
import { AiOutlinePhone } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postRegister } from "../../../redux/reducers/auth";
import AsyncCreatableSelect from "react-select/async-creatable";
import { getCompaniesSearch } from "../../../redux/reducers/companies";
import Joi from "joi";
import ClipLoader from "react-spinners/ClipLoader";

function Registraion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.auth.loading);

  // show && hidden passpord
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // useState

  const [register, setRegister] = useState({
    name: "",
    password: "",
    employee: {
      position: "",
      phone_number: "",
    },
  });
  const [err, setErr] = useState([]);
  const [errBack, setErrBack] = useState([]);

  // const [addCompany, setAddCompany] = useState(false);

  const HandleSearchCompany = (input = "") => {
    return new Promise(function (resolve) {
      const newArr = [];
      dispatch(getCompaniesSearch(input)).then((res) => {
        res.payload.results.map((item) =>
          newArr.push({ value: item.id, label: item.company_name })
        );
        resolve(newArr);
      });
    });
  };

  // Catch errors to display next to input field

  function getCurrentErr(key) {
    for (const e of err) {
      if (e.context.key === key) {
        return e.message;
      }
    }
    return "";
  }

  const onchange = (e) => {
    let filteredErrors = [];
    if (e.target.name === "position" || e.target.name === "phone_number") {
      setRegister({
        ...register,
        employee: { ...register.employee, [e.target.name]: e.target.value },
      });
      filteredErrors = err?.filter((k) => k?.path[1] !== e.target.name);
    } else {
      setRegister({ ...register, [e.target.name]: e.target.value });
      filteredErrors = err?.filter((k) => k?.path[0] !== e.target.name);
    }
    setErr(filteredErrors);
    setErrBack("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let schema = Joi.object({
      name: Joi.string().required(),
      email: Joi.string()
        .email({ minDomainSegments: 2, tlds: ["com", "net"] })
        .required()
        .label("Email"),
      // .pattern(/^[a-zA-Z0-9_.+-]+@(?!(gmail|yahoo)).*\.[a-zA-Z]{2,6}$/)
      // .rule({ message: "please enter your Business account" })
      password: Joi.string().required().label("Password"),
      employee: Joi.object({
        phone_number: Joi.string()
          .min(8)
          .max(15)
          .pattern(/^[0-9]+$/)
          .required()
          .label("Phone Number"),
        position: Joi.string().required().label("Position"),
      }).required(),
      company: Joi.number().required(),
    });

    let joiResponse = schema.validate(register, { abortEarly: false });
    if (joiResponse.error) {
      setErr(joiResponse.error.details);
    } else {
      try {
        dispatch(postRegister({ ...register })).then((res) => {
          if (res.meta.requestStatus !== "rejected") {
            navigate("/");
          } else {
            setErrBack(res.payload.detail);
          }
        });
      } catch (error) {}
    }
  };

  return (
    <div className="container-fluid p-0 py-md-0 py-3 m-0">
      <div className="row justify-content-center align-items-center p-0 m-0">
        <div className="col-md-6 d-lg-block d-none p-0 m-0">
          <div className="position-relative">
            <img
              className="w-100 position-relative"
              src={img1}
              alt="img1"
              style={{
                height: "100vh",
                objectFit:"cover"
              }}
            />
            <h1
              className="position-absolute font_A"
              style={{
                width: "176px",
                height: "95px",
                left: "54.42px",
                top: "36px",
                fontWeight: 400,
                fontSize: "47.5px",
                lineHeight: "95px",
                color: "#FFFFFF",
                letterSpacing: "5.2px",
              }}
            >
              Surplus
            </h1>
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center align-items-center" style={{minHeight:"100vh"}}>
          <h1
            className="font"
            style={{
              fontWeight: 600,
              fontSize: "48px",
              lineHeight: "72px",
              color: "rgba(1, 73, 124, 1)",
            }}
          >
            Sign Up
          </h1>
          <p
          className="text-center"
            style={{
              fontWeight: 500,
              fontSize: "24px",
              lineHeight: "38px",
              color: "rgba(1, 73, 124, 1)",
            }}
          >
            <span className="text-danger">Welcome,</span>We are happy to join us
          </p>
          <div>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <div
                  className="row justify-content-center align-items-center g-4"
                  style={{
                    width: "100%",
                  }}
                >
                  <div className="col-md-6">
                    <FormControl
                      variant="outlined"
                      style={{
                        maxWidth: "350px",
                        width:"100%"
                      }}
                    >
                      <InputLabel htmlFor="outlined-adornment-email">
                        {getCurrentErr("name")
                          ? getCurrentErr("name") &&
                            "You must write a valid name"
                          : errBack.length === 0
                          ? "Name"
                          : errBack}
                      </InputLabel>
                      <OutlinedInput
                        error={
                          getCurrentErr("name") || errBack.length !== 0
                            ? true
                            : false
                        }
                        onChange={onchange}
                        name="name"
                        required
                        defaultValue=""
                        id="outlined-adornment-email"
                        type="email"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <CiUser
                                style={{
                                  color: "rgba(230, 230, 230, 1)",
                                }}
                              />
                            </IconButton>
                          </InputAdornment>
                        }
                        label={
                          getCurrentErr("name")
                            ? getCurrentErr("name") &&
                              "You must write a valid name"
                            : errBack.length === 0
                            ? "Name"
                            : errBack
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="col-md-6">
                    <FormControl
                      variant="outlined"
                      style={{
                        maxWidth: "350px",
                        width:"100%"
                      }}
                    >
                      <InputLabel htmlFor="outlined-adornment-email">
                        {getCurrentErr("phone_number")
                          ? getCurrentErr("phone_number") &&
                            "You must write a valid Phone Number"
                          : errBack.length === 0
                          ? "Phone Number"
                          : errBack}
                      </InputLabel>
                      <OutlinedInput
                        onChange={onchange}
                        name="phone_number"
                        required
                        defaultValue=""
                        error={
                          getCurrentErr("phone_number") || errBack.length !== 0
                            ? true
                            : false
                        }
                        id="outlined-adornment-number"
                        type="number"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <AiOutlinePhone
                                style={{
                                  color: "rgba(230, 230, 230, 1)",
                                }}
                              />
                            </IconButton>
                          </InputAdornment>
                        }
                        label={
                          getCurrentErr("phone_number")
                            ? getCurrentErr("phone_number") &&
                              "You must write a valid Phone "
                            : errBack.length === 0
                            ? "Phone Number"
                            : errBack
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="col-md-6">
                    <FormControl
                      variant="outlined"
                      style={{
                        maxWidth: "350px",
                        width:"100%"
                      }}
                    >
                      <InputLabel htmlFor="outlined-adornment-email">
                        {getCurrentErr("email")
                          ? getCurrentErr("email") &&
                            "You must write a valid Email "
                          : errBack.length === 0
                          ? "Email"
                          : errBack}
                      </InputLabel>
                      <OutlinedInput
                        onChange={onchange}
                        name="email"
                        required
                        defaultValue=""
                        id="outlined-adornment-email"
                        type="email"
                        error={
                          getCurrentErr("email") || errBack.length !== 0
                            ? true
                            : false
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <SlEnvolope
                                style={{
                                  color: "rgba(230, 230, 230, 1)",
                                }}
                              />
                            </IconButton>
                          </InputAdornment>
                        }
                        label={
                          getCurrentErr("email")
                            ? getCurrentErr("email") &&
                              "You must write a valid Email "
                            : errBack.length === 0
                            ? "Email"
                            : errBack
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="col-md-6">
                    <FormControl
                      variant="outlined"
                      style={{
                        maxWidth: "350px",
                        width:"100%"
                      }}
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        {getCurrentErr("password")
                          ? getCurrentErr("password") &&
                            "You must write a valid Password "
                          : errBack.length === 0
                          ? "Password"
                          : errBack}
                      </InputLabel>
                      <OutlinedInput
                        error={
                          getCurrentErr("password") || errBack.length !== 0
                            ? true
                            : false
                        }
                        onChange={onchange}
                        name="password"
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff
                                  style={{
                                    color: "rgba(230, 230, 230, 1)",
                                  }}
                                />
                              ) : (
                                <Visibility
                                  style={{
                                    color: "rgba(230, 230, 230, 1)",
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label={
                          getCurrentErr("password")
                            ? getCurrentErr("password") &&
                              "You must write a valid Password "
                            : errBack.length === 0
                            ? "Password"
                            : errBack
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="col-md-6">
                    <FormControl
                      variant="outlined"
                      style={{
                        maxWidth: "350px",
                        width:"100%"
                      }}
                    >
                      {/* <InputLabel htmlFor="outlined-adornment-email">
                        {getCurrentErr("company")
                          ? getCurrentErr("company") &&
                            "You must write a valid company "
                          : errBack.length === 0
                          ? "Company"
                          : errBack}
                      </InputLabel>
                      <OutlinedInput
                        error={
                          getCurrentErr("company") || errBack.length !== 0
                            ? true
                            : false
                        }
                        onChange={onchange}
                        name="company"
                        required
                        defaultValue=""
                        id="outlined-adornment-email"
                        type="email"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <IoIosArrowDown
                                style={{
                                  color: "rgba(230, 230, 230, 1)",
                                }}
                              />
                            </IconButton>
                          </InputAdornment>
                        }
                        label={
                          getCurrentErr("company")
                            ? getCurrentErr("company") &&
                              "You must write a valid company "
                            : errBack.length === 0
                            ? "Company"
                            : errBack
                        }
                      /> */}
                      <FormGroup className="mb-0" controlId="Company Name">
                        <AsyncCreatableSelect
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: state.isFocused ? "55.96px" : "55.96px",
                              borderColor:
                                register.company === undefined
                                  ? getCurrentErr("company")
                                    ? "red"
                                    : "#ced4da"
                                  : "#ced4da",
                            }),
                          }}
                          placeholder={
                            getCurrentErr("company")
                              ? getCurrentErr("company") &&
                                "You must Choose Company Name "
                              : errBack.length === 0
                              ? "Choose Company Name"
                              : errBack
                          }
                          name="company"
                          className="w-100"
                          onChange={(e) => {
                            // setAddCompany(false);
                            setRegister({ ...register, company: e.value });
                            // e._isNew_ && setAddCompany(true);
                          }}
                          loadOptions={HandleSearchCompany}
                          defaultOptions
                          cacheOptions
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                  <div className="col-md-6">
                    <FormControl
                      variant="outlined"
                      style={{
                        maxWidth: "350px",
                        width:"100%"
                      }}
                    >
                      <InputLabel htmlFor="outlined-adornment-email">
                        {getCurrentErr("position")
                          ? getCurrentErr("position") &&
                            "You must write a valid position "
                          : errBack.length === 0
                          ? "Position"
                          : errBack}{" "}
                      </InputLabel>
                      <OutlinedInput
                        error={
                          getCurrentErr("position") || errBack.length !== 0
                            ? true
                            : false
                        }
                        onChange={onchange}
                        required
                        defaultValue=""
                        name="position"
                        id="outlined-adornment-email"
                        type="email"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <IoIosArrowDown
                                style={{
                                  color: "rgba(230, 230, 230, 1)",
                                }}
                              />
                            </IconButton>
                          </InputAdornment>
                        }
                        label={
                          getCurrentErr("position")
                            ? getCurrentErr("position") &&
                              "You must write a valid position "
                            : errBack.length === 0
                            ? "Position"
                            : errBack
                        }
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  <div>
                    <Checkbox size="large" />
                  </div>
                  <div
                    className="p-0 m-0 py-md-0 py-3 text-muted"
                    style={{
                      fontWeight: 500,
                      maxWidth: "725.15px",
                      fontSize: "16.31px",
                      lineHeight: "19.57px",
                      color: "rgba(70, 143, 175, 1)",
                    }}
                  >
                    By creating an account means you’re okay with our
                    <span
                      className="ps-1"
                      style={{
                        fontWeight: 600,
                        fontSize: "16.31px",
                        lineHeight: "19.57px",
                        color: "rgba(70, 143, 175, 1)",
                      }}
                    >
                      terms of service, privacy and policy
                    </span>
                  </div>
                </div>
                <button
                  onClick={onSubmit}
                  style={{
                    maxWidth: "438px",
                    width:"100%",
                    background: "rgba(1, 73, 124, 1)",
                    color: "#fff",
                    height: "60px",
                    padding: "0px 20.9279px",
                    fontWeight: 700,
                    fontSize: "20.9279px",
                    lineHeight: "120%",
                    borderRadius: "10px",
                    border: "none",
                  }}
                >
                  {loading ? (
                    <ClipLoader height="10" color="#36d7b7" />
                  ) : (
                    "Sign Up"
                  )}
                </button>
                <p
                  className="text-muted mt-4"
                  style={{
                    color: "#959DA9",
                    textDecoration: "none",
                    fontWeight: 400,
                    fontSize: "20.9279px",
                    lineHeight: "120%",
                  }}
                >
                  You have an account?
                  <span className="ms-2">
                    <Link
                      to="/login"
                      style={{
                        color: "rgba(98, 165, 194, 1)",
                        textDecoration: "none",
                        fontWeight: 600,
                        fontSize: "20.9279px",
                        lineHeight: "120%",
                      }}
                    >
                      Sign in
                    </Link>
                  </span>
                </p>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registraion;
