import { useState } from "react";
import MyFinances from "./MyFinances/MyFinances";
import MyOrders from "./MyOrders/MyOrders";
import MyReviews from "./MyReviews/MyReviews";

const ProfileDetails = () => {
  const profileTabs = ["My Orders", "My Reviews", "My Finances"];
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div >
      <div className="d-flex gap-5" style={{overflow:"auto"}} >
        <div className=" text-light gap-2 d-flex  align-items-center py-md-0 py-3" style={{minWidth:"600px"}}>
          {profileTabs.map((tab, index) => {
            return (
              <p
                className="p-0 m-0 p-3 px-5 active-tab"
                key={index}
                style={{
                  backgroundColor: activeTab === index ? "#fff" : "#2A6F98",
                  color: activeTab === index ? "var(--darkblue)" : "#fff",
                }}
                onClick={() => setActiveTab(index)}
              >
                {tab}
              </p>
            );
          })}
        </div>
      </div>

      <div className="p-5 pb-0 active-comp mb-5" style={{overflow:"auto"}}>
        {activeTab === 0 && <MyOrders/>}
        {activeTab === 1 && <MyReviews/>}
        {activeTab === 2 && <MyFinances/>}
        </div>
    </div>
  );
};

export default ProfileDetails;
