import React, { useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";
import Dropdown from "../../Dropdown/Dropdown";
import { STYLING } from "../OrdersCard/styling";
import HorizontalLine from "../../Lines/HorizontalLine";
import AllStages from "./AllStages";
import { useSelector } from "react-redux";

const TrackOrder = (props) => {
  const { order } = props;
  const [copyOrder, setCopyOrder] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(
    order?.items?.[0]?.product_details?.title?.slice(0, 20) ? order?.items?.[0]?.product_details?.title?.slice(0, 20) : order?.whole_sale?.[0]?.title + "..."
  );
  // const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(
    order?.items?.[0]?.status
  );
  const loading = useSelector((state) => state?.orders?.loading);

  useEffect(() => {
    setSelectedStatus(order?.items?.[0]?.status);
    setSelectedProduct(
		order?.items?.[0]?.product_details?.title?.slice(0, 20) ? order?.items?.[0]?.product_details?.title?.slice(0, 20) : order?.whole_sale?.[0]?.title + "..."

    );
    let copyOrder = [];
    order?.items?.map((item) => {
      copyOrder.push(
        item?.product_details?.title?.length > 20
          ? item?.product_details?.title?.slice(0, 20) + "..."
          : item?.product_details?.title
      );
    });
    order?.whole_sale?.map((item) => {
      copyOrder.push(
        item?.title?.length > 20
          ? item?.title?.slice(0, 20) + "..."
          : item?.title
      );
    });
    setCopyOrder(copyOrder);
  }, [order]);

  useEffect(() => {
    const i = copyOrder?.findIndex((item) => item == selectedProduct);
    const status = order?.items?.[i]?.status;
    const wholsaleIndex = order?.whole_sale?.findIndex((item) =>
      item?.title?.includes(selectedProduct)
    );
    if (i > -1) {
      setSelectedStatus(status);
    }
    if (wholsaleIndex > -1) {
      setSelectedStatus(order?.whole_sale?.[wholsaleIndex]?.status?.[0]);
    }
    // console.log( copyOrder)
  }, [selectedProduct]);
  // console.log(selectedProduct)

  return (
    <Card
      style={{
        backgroundColor: STYLING.bgColors.light,
        borderColor: STYLING.borders.colors.grey,
        minHeight: "513px",
      }}
      className="mt-1 p-4 shadow"
    >
      <Row>
        <p
          style={{
            color: STYLING.colors.dark,
            fontWeight: STYLING.font.bold,
            fontSize: STYLING.font.size.medium,
          }}
          className="mb-1"
        >
          Track your order
        </p>
        <p
          style={{
            color: STYLING.colors.dark,
            fontWeight: STYLING.font.bold,
            fontSize: STYLING.font.size.small,
          }}
        >
          Choose product to track
        </p>
      </Row>
      <Row>
        {selectedProduct != 'undefined...' && (
          <Dropdown
            setSelectedProduct={setSelectedProduct}
            full={true}
            placeholder={selectedProduct}
            choices={copyOrder && copyOrder}
          />
        )}
      </Row>
      <HorizontalLine />
      <AllStages
        current={selectedStatus?.id}
        type="order"
        placeholder={selectedProduct}
      />
    </Card>
  );
};

export default TrackOrder;
