import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import user from "../../../../../assets/ProductPage/user.png";
import StarsRate from "../../../../../components/StarsRate/StarsRate";
import { EditReview, getReviews } from "../../../../../redux/reducers/reviews";

const ReviewCard = ({ review, edit }) => {
  const [clickedStar, setClickedStar] = useState(review?.rate);
  const [reviewData, setReviewData] = useState(review)
const dispatch = useDispatch()
const onChange = (e) => {
	setReviewData({ ...reviewData, [e.target.name]: e.target.value });
}
const onSubmit = () => {
	dispatch(EditReview(reviewData)).then((res) => dispatch(getReviews()))
}

useEffect(()=>{
	setReviewData({ ...reviewData, rate: clickedStar, product: reviewData?.product?.id, company:reviewData?.company?.id});

},[clickedStar])

  return (
    <div
      className="row p-3 gap-2"
      style={{
        background: "rgba(42, 111, 152, 0.03)",
        borderRadius: 0,
      }}
    >
      <div className="col-md-3 d-flex flex-column justify-content-center align-items-center">
        <img
          width={75}
          className="rounded-circle"
          src={
            review?.company?.business_image
              ? review?.company?.business_image
              : user
          }
          alt={review?.company?.company_name}
        />
        <p
          className="title-details p-0 m-0 text-center"
          style={{ fontSize: 16, lineHeight: 2 }}
        >
          {review?.company?.company_name}
        </p>
        <p className="p-0 m-0">{moment(review?.created).fromNow()}</p>
      </div>
      {edit ? (
        <div className="col-md-8">
          <div className="d-flex justify-content-between align-items-center gap-2 mt-3 mb-2">
			<input name="title" onChange={onChange} type="text" defaultValue={review?.title} className="form-control w-100 shadow-none" />
            <div style={{maxWidth:"140px",width:"100%"}}>
              <StarsRate
                num={review?.rate}
                size={20}
				style={{cursor:"pointer"}}
                clicked={true}
                setClickedStar={setClickedStar}
              />
            </div>
          </div>
		  <textarea name="body" onChange={onChange} defaultValue={review?.body} className="form-control shadow-none" cols="30" rows="5"></textarea>
		  <button className="btn btn-dark" onClick={onSubmit}>done</button>
        </div>
      ) : (
        <div className="col-md-8">
          <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mb-2">
            <p
              className="title-details p-0 m-0"
              style={{ fontWeight: 600, fontSize: 20 }}
            >
              {review?.title ? review?.title : "Not Available"}
            </p>
            <div>
              <StarsRate
                num={review?.rate}
                size={20}
                setClickedStar={setClickedStar}
              />
            </div>
          </div>
          <p>{review?.body}</p>
        </div>
      )}
    </div>
  );
};

export default ReviewCard;
