import React, { useEffect, useState } from "react";
import img from "../../assets/img/Rectangle 81.png";
import { IoIosArrowDropright } from "react-icons/io";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
// import required modules
import { Pagination, Autoplay, EffectCreative } from "swiper";
import CategoryCards from "../../components/CategoryCards/CategoryCards";
import { useDispatch, useSelector } from "react-redux";
import MostPopular from "./MostPopular/MostPopular";
import NewArrivals from "./NewArrivals/NewArrivals";
import TopRated from "./TopRated/TopRated";
import TopCompanies from "./TopCompanies/TopCompanies";
import { getCategories } from "../../redux/reducers/categories";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { getFavorites } from "../../redux/reducers/favorites";

export default function Home(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [currentCategory, setCurrentCategory] = useState(null);

  //redux
  const dispatch = useDispatch();
  const categoriesList = useSelector((state) => state.categories.categories);
  const loading = useSelector((state) => state.categories.loading);
  const categories = categoriesList?.results;

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getFavorites());
  }, []);

  useEffect(() => {
	let copyCategories = categories?.filter((cat)=> cat?.subcategories?.length !== 0)
    setCurrentCategory(copyCategories?.[0]);
  }, [categories]);

  // console.log(categories?.filter((cat)=> cat?.subcategories?.length !== 0))

  return (
    <div className="container my-3">
      <div className="row align-items-start">
        <div className="col-lg-8">
          <div className="p-lg-0 p-2 position-relative">
            <Swiper
              loop={true}
              modules={[Pagination, Autoplay, EffectCreative]}
              effect={"creative"}
              speed={1200}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              creativeEffect={{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400],
                },
                next: {
                  translate: ["100%", 0, 0],
                },
              }}
              pagination={{
                el: ".my-custom-pagination-div",
                clickable: true,
                renderBullet: (index, className) => {
                  return (
                    '<span class="' + className + '">' + (index + 1) + "</span>"
                  );
                },
              }}
              className="mySwiper position-relative"
            >
              <SwiperSlide className="position-relative">
                <img src={img} className="w-100" alt="home" />
                <div
                  className="d-flex position-absolute w-100 flex-column"
                  style={{ bottom: "40px", left: "20px", zIndex: "3" }}
                >
                  <h2
                    className="text-white display-5 m-0"
                    style={{
                      fontFamily: "Anton",
                      letterSpacing: "2px",
                      textShadow: "2px 2px rgba(0, 0, 0,0.3)",
                    }}
                  >
                    WHITE FRIDAYS OFFERS
                  </h2>
                  <p
                    className="text-white m-0"
                    style={{ textShadow: "2px 2px rgba(0, 0, 0,0.3)" }}
                  >
                    50% off on all machinery
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="position-relative">
                <img src={img} className="w-100" alt="home" />
                <div
                  className="d-flex position-absolute w-100 flex-column"
                  style={{ bottom: "40px", left: "20px", zIndex: "3" }}
                >
                  <h2
                    className="text-white display-5 m-0"
                    style={{
                      fontFamily: "Anton",
                      letterSpacing: "2px",
                      textShadow: "2px 2px rgba(0, 0, 0,0.3)",
                    }}
                  >
                    WHITE FRIDAYS OFFERS
                  </h2>
                  <p
                    className="text-white m-0"
                    style={{ textShadow: "2px 2px rgba(0, 0, 0,0.3)" }}
                  >
                    50% off on all machinery
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="position-relative">
                <img src={img} className="w-100" alt="home" />
                <div
                  className="d-flex position-absolute w-100 flex-column"
                  style={{ bottom: "40px", left: "20px", zIndex: "3" }}
                >
                  <h2
                    className="text-white display-5 m-0"
                    style={{
                      fontFamily: "Anton",
                      letterSpacing: "2px",
                      textShadow: "2px 2px rgba(0, 0, 0,0.3)",
                    }}
                  >
                    WHITE FRIDAYS OFFERS
                  </h2>
                  <p
                    className="text-white m-0"
                    style={{ textShadow: "2px 2px rgba(0, 0, 0,0.3)" }}
                  >
                    50% off on all machinery
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
            <div
              class="my-custom-pagination-div"
              style={{
                position: "absolute",
                display: "flex",
                paddingBottom: "1.5rem",
                paddingRight: "2rem",
                justifyContent: "end",
                zIndex: 3,
                listStyle: "none !important",
              }}
            />
          </div>
        </div>
        <div className="col-lg-4 d-flex flex-column align-items-center gap-lg-4">
          <div className="p-lg-0 p-2 w-100 h-100">
            <Link
              to="/categories"
              className="d-flex text-decoration-none rounded-3 align-items-end  p-4"
              style={{ backgroundColor: "var(--mdBlue)", height: "182px" }}
            >
              <div className="d-flex align-items-center justify-content-between w-100">
                <h2 className="fw-bold text-white fs-2">Shop By Category</h2>
                <IoIosArrowDropright className="fs-1 text-white" />
              </div>
            </Link>
          </div>
          <div className="p-lg-0 p-2 w-100 h-100">
            <Link
              to="/brands"
              className="d-flex text-decoration-none rounded-3 align-items-end  p-4"
              style={{ backgroundColor: "var(--purple)", height: "182px" }}
            >
              <div className="d-flex align-items-center justify-content-between w-100">
                <h2 className="fw-bold text-white fs-2">Shop By Brand</h2>
                <IoIosArrowDropright className="fs-1 text-white" />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="row px-3 shadow rounded-3 p-0 my-5">
        <div className="" style={{ overflow: "auto" }}>
          <div
            className=" d-flex justify-content-evenly fw-bold align-items-center gap-2 p-0"
            style={{ minWidth: "1000px" }}
          >
            {loading ? (
              <div className=" d-flex gap-2 justify-content-center ">
                {Array(6)
                  .fill(0)
                  .map(() => (
                    <li>
                      <Skeleton
                        className="rounded-2 mt-2"
                        variant="rectangular"
                        width={166.86}
                        height={55.98}
                      />
                    </li>
                  ))}
              </div>
            ) : (
              categories?.filter((cat) => cat?.subcategories?.length !== 0).map((category, index) => {
                return (
                  <p
                    role="button"
                    className={
                      activeTab === index
                        ? " text-center py-3 w-100"
                        : "py-3 text-center w-100"
                    }
                    style={{
                      color: activeTab === index ? "var(--blue)" : "#fff",
                      backgroundColor:
                        activeTab === index ? "#fff" : "var(--mdBlue)",
                      borderTopRightRadius: 10,
                      borderTopLeftRadius: 10,
                    }}
                    onClick={() => {
                      setActiveTab(index);
                      setCurrentCategory(category);
                    }}
                    key={index}
                  >
                    {category?.title}
                  </p>
                );
              })
            )}
            <Link
              to="/categories"
              className="py-3 text-decoration-none text-center w-100"
              style={{
                color: "var(--blue)",
              }}
            >
              View All
            </Link>
          </div>
        </div>
        <CategoryCards data={currentCategory} />
      </div>
      <MostPopular />
      <TopCompanies />
      <NewArrivals />
      <TopRated />
    </div>
  );
}
