import { useState } from "react"

const Gallery = ({ gallery }) => {
	//states
	const [activeImg, setActiveImg] = useState(0)

	return (
		<div className='d-flex gallery'>
			<div className='gallery-thumbnails'>
				{gallery?.map((img, index) => {
					return (
						<div
							className='img-box'
							key={index}
							onClick={() => setActiveImg(index)}
						>
							<img
								src={img}
								alt='product'
								className='rounded-3 w-100 h-100'
								style={{
									objectFit: "cover",
									border: activeImg === index ? "1px solid var(--blue)" : "",
								}}
							/>
						</div>
					)
				})}
			</div>
			<div className='gallery-img'>
				<img src={gallery?.[activeImg]} alt='' className='rounded-3' />
			</div>
		</div>
	)
}

export default Gallery
