import { InputAdornment, TextField } from "@mui/material";
import {
  Person2Outlined,
  PhoneOutlined,
  EmailOutlined,
} from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Egypt from "../../../../../assets/common/egypt.png";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { contactUs } from "../../../../../redux/reducers/companies";

const ContactForm = ({ company }) => {
  const dispatch = useDispatch();
  const [contactUsData, setContactUsData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    company: company?.id,
  });

  const handleData = (e) => {
    setContactUsData({
      ...contactUsData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div
      style={{
        maxWidth: "650px",
        width: "90%",
        margin: "1rem auto",
      }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <TextField
        id="outlined-basic"
        label="Enter Your Name"
        name="name"
        onChange={handleData}
        variant="outlined"
        className="w-100"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Person2Outlined />
            </InputAdornment>
          ),
        }}
      />
      <div className="d-flex gap-2 w-100 my-4">
        {/* <FormControl className="w-60" style={{ width: 120 }}>
          <InputLabel id="demo-simple-select-label">Country</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Country"
            style={{
              width: 100,
              height: 55,
            }}
          >
            <MenuItem value={"+20"}>
              <img src={Egypt} alt="egypt" /> +20
            </MenuItem>
            <MenuItem value={"+20"}>
              <img src={Egypt} alt="egypt" /> +20
            </MenuItem>
            <MenuItem value={"+20"}>
              <img src={Egypt} alt="egypt" /> +20
            </MenuItem>
          </Select>
        </FormControl> */}

        <TextField
          id="outlined-basic"
          label="Phone Number"
          name="phone"
          onChange={handleData}
          variant="outlined"
          className="w-100"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <PhoneOutlined />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center gap-4 w-100">
        <TextField
          id="outlined-basic"
          label="Email"
          name="email"
          onChange={handleData}
          variant="outlined"
          className="w-100"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <EmailOutlined />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="outlined-basic"
          label="Your Message"
          name="message"
          onChange={handleData}
          variant="outlined"
          className="w-100"
          multiline
          rows={4}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <EmailOutlined />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="w-100 mt-4 d-flex justify-content-center align-items-center">
        <button
          type="submit"
          onClick={() => dispatch(contactUs(contactUsData))}
          className="btn text-light py-3"
          style={{
            backgroundColor: "#024F87",
            maxWidth: "200px",
            width: "100%",
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ContactForm;
