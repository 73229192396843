import { BsCheckSquareFill, BsFillXSquareFill } from "react-icons/bs";
import CertificationsSlider from "./CertificationsSlider/CertificationsSlider";

const Certifications = () => {
  const includedServices = ["Repairs", "Monthly checkups", "Spare parts"];
  const excludedServices = ["Repairs", "Monthly checkups", "Spare parts"];
  return (
      <div className="row justify-content-center align-items-center pb-3">
        <h3 className="title-details my-md-5 my-3">Warranty</h3>
        <div className="row justify-content-center align-items-center gap-3">
          <div className="col-md-3 d-flex justify-content-center">
            <div
              style={{ backgroundColor: "var(--lightBlue)" }}
              className="years-box"
            >
              <h3 className="text-light num-title">5</h3>
              <h3 className="text-light year-title">Years</h3>
            </div>
          </div>
          <div className="col-md-4">
            <h4 className="mb-3">Included Services</h4>
            {includedServices.map((item, index) => {
              return (
                <div
                  key={index}
                  className="mb-2 d-flex gap-2 align-items-center"
                >
                  <BsCheckSquareFill color="var(--green)" size={20}/>{" "}
                  <span style={{ color: "var(--darkblue)", fontSize: 20 }}>
                    {item}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="col-md-3">
            <h4 className="mb-3">Excluded Services</h4>
            {excludedServices.map((item, index) => {
              return (
                <div
                  key={index}
                  className="mb-2 d-flex gap-2 align-items-center"
                >
                  <BsFillXSquareFill color="var(--purple)" size={20} />{" "}
                  <span style={{ color: "var(--darkblue)", fontSize: 20 }}>
                    {item}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
          <CertificationsSlider/>
      </div>
  );
};

export default Certifications;
