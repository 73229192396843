import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { BASE_URL } from "../common"

export const getbrands = createAsyncThunk(
	"brands/get",
	async (data, { rejectWithValue }) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			}
			const response = await axios.get(`${BASE_URL}/products/brands`, config)
			return response.data
		} catch (error) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data)
			} else {
				return rejectWithValue(error.message)
			}
		}
	}
)
const initialState = {
	loading: false,
	brands: null,
	brand: null,
	error: false,
}

const brandsSlice = createSlice({
	name: "brands",
	initialState,
	reducers: {},
	extraReducers: {
		//get products
		[getbrands.pending]: (state, action) => {
			state.loading = true
		},
		[getbrands.fulfilled]: (state, action) => {
			state.loading = false
			state.brands = action.payload
		},
		[getbrands.rejected]: (state, action) => {
			state.loading = false
			state.error = action.payload
		},
	},
})

export default brandsSlice.reducer
