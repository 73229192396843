import React from "react"
import { Col, Row } from "react-bootstrap"

const HorizontalLine = (props) => {
	const { color, className } = props

	return (
		<Row className={className ? className : "my-3"}>
			<Col>
				<div
					style={{ backgroundColor: color ? color : "#FFFFFF", height: "1px" }}
				/>
			</Col>
		</Row>
	)
}

export default HorizontalLine
