import React, { useState } from "react"
import img1 from "../../../assets/auth/Category Banner.png"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import Input from "@mui/material/Input"
import FilledInput from "@mui/material/FilledInput"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import InputAdornment from "@mui/material/InputAdornment"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
// import { HiOutlineEnvelope } from "react-icons/hi";
import { SlEnvolope } from "react-icons/sl"
import Joi from "joi"
import { sendEmail } from "../../../redux/reducers/auth"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import ClipLoader from "react-spinners/ClipLoader"

function ForgetPassword() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const loading = useSelector((state) => state.auth.loading)

	// useState

	const [sendEmailConfirm, setSendEmailConfirm] = useState({
		email: "",
	})
	const [err, setErr] = useState([])
	const [errBack, setErrBack] = useState([])

	// Catch errors to display next to input field

	function getCurrentErr(key) {
		for (const e of err) {
			if (e.context.key === key) {
				return e.message
			}
		}
		return ""
	}

	// Store data in the usestate

	const onchange = (e) => {
		setSendEmailConfirm({
			...sendEmailConfirm,
			[e.target.name]: e.target.value,
		})

		//filter the current error only

		let filteredErrors = []
		filteredErrors = err?.filter((k) => k?.path[0] !== e.target.name)
		setErr(filteredErrors)
		setErrBack("")
	}

	// submit to done or get errors

	const onSubmit = (e) => {
		e.preventDefault()
		let schema = Joi.object({
			email: Joi.string()
				.email({ minDomainSegments: 2, tlds: ["com", "net"] })
				.required()
				.label("Email"),
			// .pattern(/^[a-zA-Z0-9_.+-]+@(?!(gmail|yahoo)).*\.[a-zA-Z]{2,6}$/)
			// .rule({ message: "please enter your Business account" }),
		})

		let joiResponse = schema.validate(sendEmailConfirm, { abortEarly: false })

		if (joiResponse.error) {
			setErr(joiResponse.error.details)
		} else {
			try {
				dispatch(sendEmail({ ...sendEmailConfirm })).then((res) => {
					if (res.meta.requestStatus !== "rejected") {
						navigate("/")
					} else {
						// setErr(res.payload.detail);
						setErrBack(res.payload)
					}
				})
			} catch (error) {}
		}
	}

  return (
    <div className="container-fluid p-0 m-0">
      <div className="row justify-content-center p-0 m-0">
        <div className="col-lg-6 d-lg-block d-none p-0 m-0">
          <div className="position-relative">
            <img
              className="w-100 position-relative"
              src={img1}
              alt="img1"
              style={{
                height: "100vh",
                objectFit:"cover"
              }}
            />
            <h1
              className="position-absolute font_A"
              style={{
                width: "176px",
                height: "95px",
                left: "54.42px",
                top: "36px",
                fontWeight: 400,
                fontSize: "47.5px",
                lineHeight: "95px",
                color: "#FFFFFF",
                letterSpacing: "5.2px",
              }}
            >
              Surplus
            </h1>
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center align-items-center" style={{minHeight:"100vh"}}>
          <h1
            className="font"
            style={{
              fontWeight: 700,
              fontSize: "48px",
              lineHeight: "72px",
              color: "#183A59",
              maxWidth: "422px",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            Forget Password
          </h1>
          <p
            style={{
              color: "rgba(98, 165, 194, 1)",
              textDecoration: "none",
              fontWeight: 600,
              fontSize: "20.9279px",
              lineHeight: "120%",
            }}
            className="text-center"
          >
            Please Enter Your Phone Number To Recieve A
          </p>
          <p
            style={{
              color: "rgba(98, 165, 194, 1)",
              textDecoration: "none",
              fontWeight: 600,
              fontSize: "20.9279px",
              lineHeight: "120%",
            }}
          >
            {" "}
            Verification Code
          </p>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
                <FormControl className="mb-4 mt-4 w-100" variant="outlined" >
                  <InputLabel htmlFor="outlined-adornment-email">
                    {getCurrentErr("email")
                      ? getCurrentErr("email") &&
                        "You must write a valid Email "
                      : errBack?.length === 0
                      ? "Email"
                      : errBack}
                  </InputLabel>
                  <OutlinedInput
                    error={
                      getCurrentErr("email") || errBack.length !== 0
                        ? true
                        : false
                    }
                    onChange={onchange}
                    style={{
                      maxWidth: "438px",
                      width: "100%",
                      borderRadius: "5px",

                    }}
                    required
                    defaultValue=""
                    id="outlined-adornment-email"
                    name="email"
                    type="email"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SlEnvolope
                            style={{
                              color: "rgba(230, 230, 230, 1)",
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                    label={
                      getCurrentErr("email")
                        ? getCurrentErr("email") &&
                          "You must write a valid Email "
                        : errBack.length === 0
                        ? "Email"
                        : errBack
                    }
                  />
                </FormControl>
                <button
                  onClick={onSubmit}
                  style={{
                    maxWidth: "438px",
                    width:"100%",
                    background: "rgba(1, 73, 124, 1)",
                    color: "#fff",
                    height: "60px",
                    padding: "0px 20.9279px",
                    fontWeight: 700,
                    fontSize: "20.9279px",
                    lineHeight: "120%",
                    borderRadius: "10px",
                    border: "none",
                  }}
                  type="submit"
                >
                  {loading ? (
                    <ClipLoader height="10" color="#36d7b7" />
                  ) : (
                    "Continue"
                  )}
                </button>
             
            </Box>
         
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword
