import ContactDetails from "./ContanctDetails/ContactDetails";
import ContactForm from "./ContanctDetails/ContactForm";

const SupplierContactUs = (props) => {
  const { company } = props;
  return (
    <div className="row gx-0 gap-lg-0 gap-3 d-flex justify-content-between">
      <div
        style={{
          backgroundColor: "#012A4A",
          height: "600px",
        }}
        className="text-light col-lg-3 d-flex flex-column justify-content-center align-items-center"
      >
        <ContactDetails company={company} />
      </div>
      <div className="col-lg-9 d-flex flex-column justify-content-center align-items-center">
        <ContactForm company={company} />
      </div>
    </div>
  );
};

export default SupplierContactUs;
