import React, { useCallback, useEffect, useRef, useState } from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { BsSearch } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {
	getProducts,
	search,
	searchProducts,
} from "../../../redux/reducers/products"
import slugify from "react-slugify"
import { searchCategories } from "../../../redux/reducers/categories"
import { Skeleton } from "@mui/material"

const Search = () => {
	const dispatch = useDispatch()
	const [searchDropdown, setSearchDropdown] = useState(false)
	const products = useSelector((state) => state.products.search)
	const categories = useSelector((state) => state.categories.search)
	const { find, results, loading } = products
	const navigate = useNavigate()
	useEffect(() => {
		dispatch(searchProducts({ find }))
		dispatch(searchCategories(find))
	}, [find])

	const inputRef = useRef(null)
	const dropdownRef = useRef(null)
	const [width, setWidth] = useState()
	const handleResize = () => {
		setWidth(inputRef.current.offsetWidth)
	}
	const closeOpenMenus = (e) => {
		if (
			inputRef.current &&
			dropdownRef.current &&
			searchDropdown &&
			!inputRef.current.contains(e.target) &&
			!dropdownRef.current.contains(e.target)
		) {
			setSearchDropdown(false)
		}
	}
	useEffect(() => {
		width === undefined && setWidth(inputRef.current.offsetWidth)
		window.addEventListener("resize", handleResize)
		document.addEventListener("mousedown", closeOpenMenus)
		return () => {
			window.removeEventListener("resize", handleResize)
			document.removeEventListener("mousedown", closeOpenMenus)
		}
	}, [inputRef, dropdownRef, handleResize, , closeOpenMenus])

	const skeleton = Array(6)
		.fill(0)
		.map(() => (
			<li className='text-center pb-1'>
				<Skeleton variant='text' sx={{ fontSize: "1rem" }} />
			</li>
		))
	const noProducts = results.length === 0
	const noCategories = categories.results.length === 0
	return (
		<form
			className='d-flex align-items-center w-100 ms-4 position-relative '
			role='search'
			autocomplete='off'
			style={{ height: "36px" }}
		>
			<input
				className='form-control me-3 border-0 '
				type='search'
				placeholder='Search'
				aria-label='Search'
				value={find}
				ref={inputRef}
				onChange={(e) => {
					dispatch(search(e.target.value))
				}}
				onClick={() => !searchDropdown && setSearchDropdown(true)}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.preventDefault()
						find !== "" && navigate(`/search/${slugify(find.toLowerCase())}`)
						setSearchDropdown(false)
					} else setSearchDropdown(true)
				}}
			/>
			{(!noProducts || !noCategories) && (
				<div
					className='card position-absolute top-100 start-0 '
					ref={dropdownRef}
					style={{
						zIndex: 4,
						fontSize: "14px",
						minWidth: "711px",
						visibility: !searchDropdown && "hidden",
						width: width,
						height: "282px",
						padding: "27px 32px 32px 27px",
					}}
					onClick={(e) => e.preventDefault()}
				>
					<Row className='h-100'>
						{!noProducts && (
							<Col>
								<p className='searchdropdowntitle'>Search Results</p>
								{loading
									? skeleton
									: results?.slice(0, 6).map((item) => {
											const isWholesale = item?.item
											const link = isWholesale
												? `/wholesale/${item.id}`
												: `/product/${item.slug}`
											return (
												<Link
													className='text-decoration-none'
													to={link}
													onClick={() => setSearchDropdown(false)}
												>
													<li className='searchdropdownbody pb-2'>
														{`${item.title.slice(0, 36)}${
															item?.title?.length > 36 ? "..." : ""
														}`}
													</li>
												</Link>
											)
									  })}
							</Col>
						)}
						{!noCategories && (
							<Col
								className={`ps-4 h-100 ${
									!noProducts && "border-start border-2"
								}`}
								style={{ borderColor: "#C9D1D8" }}
							>
								<p className='searchdropdowntitle'>Categories</p>
								{categories?.loading
									? skeleton
									: categories?.results?.slice(0, 6).map((category) => {
											return (
												<Link
													className='text-decoration-none'
													to={`/category/${category.slug}`}
													onClick={() => setSearchDropdown(false)}
												>
													<li className='searchdropdownbody pb-2'>
														{`${category.title.slice(0, 36)}${
															category?.title?.length > 36 ? "..." : ""
														}`}
													</li>
												</Link>
											)
									  })}
							</Col>
						)}
					</Row>
				</div>
			)}
			<button
				className='btn btn-light me-4'
				onClick={(e) => {
					e.preventDefault()
					find !== "" && navigate(`/search/${slugify(find.toLowerCase())}`)
					setSearchDropdown(false)
				}}
				style={{
					borderRadius: "10px",
					color: "#01497c",
				}}
			>
				<BsSearch />
			</button>
		</form>
	)
}

export default Search
