import React, { useEffect } from "react"
import img from "../../../assets/img/Rectangle 81.png"
import { MdKeyboardArrowRight } from "react-icons/md"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/effect-creative"
// import required modules
import { Col } from "react-bootstrap"
import WholesaleCard from "../../../components/WholesaleCard/WholesaleCard"
import ItemCard from "../../../components/ItemCard/ItemCard"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getProducts } from "../../../redux/reducers/products"
import { Skeleton } from "@mui/material"

const MostPopular = () => {
	//redux
	const dispatch = useDispatch()
	const products = useSelector((state) => state.products.MPproducts)
	const loading = useSelector((state) => state.products.loading)

	// console.log(products)
	useEffect(() => {
		dispatch(getProducts({ sort: "popular" }))
	}, [dispatch])

	return (
		<div className='row my-5 align-align-items-center'>
			<div className='col-lg-3 left-column p-md-0 p-4 d-flex justify-content-center position-relative'>
				<img
					src={img}
					className='w-100 rounded-3'
					style={{ objectFit: "cover" }}
					alt='left column'
				/>
				<h2
					className='text-white text-center m-0 position-absolute'
					style={{
						fontFamily: "Anton",
						fontSize: "2rem",
						textShadow: "2px 2px rgba(0, 0, 0,0.3)",
					}}
				>
					Most Popular
				</h2>
				<Link
					to='/products/popular'
					className='text-white btn rounded-pill px-4 py-2 m-0 position-absolute'
					style={{
						textShadow: "2px 2px rgba(0, 0, 0,0.3)",
						backgroundColor: "var(--purple)",
					}}
				>
					View More <MdKeyboardArrowRight />
				</Link>
			</div>
			<Col lg={9} className='d-flex gap-3 justify-content-center flex-wrap '>
				{products == undefined ? (
					<>
						<div className=' d-flex gap-4 justify-content-center'>
							{Array(3)
								.fill(0)
								.map(() => (
									<li>
										<Skeleton variant='rectangular' width={290} height={342} />
									</li>
								))}
						</div>
						<div className=' d-flex gap-4 justify-content-center'>
							{Array(3)
								.fill(0)
								.map(() => (
									<li>
										<Skeleton variant='rectangular' width={290} height={342} />
									</li>
								))}
						</div>
					</>
				) : (
					products?.results
						.slice(0, 6)
						.map((product) =>
							product?.item ? (
								<WholesaleCard data={product} />
							) : (
								<ItemCard data={product} />
							)
						)
				)}
			</Col>
		</div>
	)
}

export default MostPopular
