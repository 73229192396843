import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../common";

export const getAddresses = createAsyncThunk(
  "addresses/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/company/purchase/company-address/`,
        {
          headers: {
            ["Authorization"]: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createAddress = createAsyncThunk(
  "addresses/post",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/company/purchase/company-address/`,
        data,
        {
          headers: {
            ["Authorization"]: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
      
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  addressesList: null,
  address: null,
  addressCreated: null,
  error: false,
};

const addressesSlice = createSlice({
  name: "addresses",
  initialState,
  reducers: {},
  extraReducers: {
    //get addresses
    [getAddresses.pending]: (state) => {
      state.loading = true;
    },
    [getAddresses.fulfilled]: (state, action) => {
      state.loading = false;
      state.addressesList = action.payload;
      state.error = false;
    },
    [getAddresses.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },

    //create address
    [createAddress.pending]: (state) => {
      state.loading = true;
    },
    [createAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.addressCreated = action.payload;
      state.error = false;
    },
    [createAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export default addressesSlice.reducer;
