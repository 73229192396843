import { KeyboardArrowRightOutlined } from "@mui/icons-material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import OrdersCard from "../../../../components/Orders/OrdersCard/OrdersCard";
import { getOrders } from "../../../../redux/reducers/orders";

const MyOrders = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrders({ find: "", sort: "" }));
  }, []);
  const orders = useSelector((state) => state?.orders?.ordersList?.results);
  // console.log(orders)
  return (
    <div style={{ height: "600px" }}>
      {orders?.length !== 0 && orders && (
        <div className="d-flex justify-content-between align-items-center mb-3">
          <p className="title-text m-0">Most recent orders</p>
          <Link
            to="/orders"
            className="btn view-all-btn px-3 text-light rounded-5 d-flex justify-content-between align-items-center gap-1"
            style={{
              backgroundColor: "#cc3363",
            }}
          >
            View All <KeyboardArrowRightOutlined />{" "}
          </Link>
        </div>
      )}

      <div className="d-flex flex-column gap-3">
        {orders?.map((item) => (
          <OrdersCard variation={1} item={item} />
        ))}
        {orders?.length === 0 && (
          <div
            style={{
              height: 500,
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <p className="text-center fw-bold">No Orders Found</p>
          </div>
        )}
        {/* {!orders && (
          <div className="d-flex justify-content-center align-items-center"       style={{
			height: 500,
		  }}>
            <ClipLoader height="10" color="rgb(42, 111, 152)" />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default MyOrders;
