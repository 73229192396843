import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAddresses } from "../../redux/reducers/addresses";
import Address from "./Adsress";

const Addresses = () => {
  const dispatch = useDispatch();
  const addressRedux = useSelector(
    (state) => state.addresses.addressesList?.results
  );


  useEffect(() => {
    dispatch(getAddresses());
  }, []);

  //   const addresses = [
  //     {
  //       country: "Egypt",
  //       city: "Cairo",
  //       address: "test address, cairo, city",
  //     },
  //     {
  //       country: "China",
  //       city: "China",
  //       address: "test address, China, China",
  //     },
  //   ];
  return (
    <div>
      {addressRedux?.map((address, index) => {
        return <Address address={address} key={index} />;
      })}
    </div>
  );
};

export default Addresses;
