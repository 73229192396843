import { LocationOnOutlined } from "@mui/icons-material";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAddress, getAddresses } from "../../redux/reducers/addresses";
import ClipLoader from "react-spinners/ClipLoader";

const iso2 = require("iso-3166-2");

const AddressForm = ({ setOpenAddressForm }) => {
  const loading = useSelector((state) => state.addresses.loading);

  const dispatch = useDispatch();
  const [addressData, setAddressData] = useState({});

  //handleChange
  const handleChange = (e) => {
    setAddressData({
      ...addressData,
      [e.target.name]: e.target.value,
    });
  };

  //function to close the form
  const HandleCloseAddressForm = () => {
    dispatch(createAddress(addressData)).then((res) => {
      setOpenAddressForm(false);
      dispatch(getAddresses());
    });
  };


  return (
    <div>
      <div className="d-flex gap-2 my-3">
        <FormControl className="w-50">
          <InputLabel id="demo-select-small">Country</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            label="Country"
            name="country"
            onChange={handleChange}
          >
            {Object.keys(iso2?.data).map((item) => (
              <MenuItem value={item}>{iso2?.data[item]?.name}</MenuItem>
            ))}
            {/* {iso.all().map((item) => (
              <MenuItem value={item?.alpha2}>{item?.country}</MenuItem>
            ))} */}
          </Select>
        </FormControl>
        <FormControl className="w-50">
          <InputLabel id="demo-select-small">City</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            label="City"
            name="city"
            onChange={handleChange}
          >
            {addressData?.country &&
              Object.keys(iso2?.country(`${addressData?.country}`)?.sub).map(
                (item) => (
                  <MenuItem
                    value={
                      iso2.country(`${addressData?.country}`)?.sub[item]?.name
                    }
                  >
                    {iso2?.country(`${addressData?.country}`)?.sub[item]?.name}
                  </MenuItem>
                )
              )}
          </Select>
        </FormControl>
      </div>
      <div className="col-12 mt-2">
        <TextField
          name="address_name"
          id="outlined-basic"
          label="Adress"
          variant="outlined"
          className="w-100"
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <LocationOnOutlined />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="w-100">
        <button
          className="btn text-light p-2 mt-3 w-50"
          id="add-address-btn"
          onClick={HandleCloseAddressForm}
        >
          {loading ? <ClipLoader /> : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default AddressForm;
