import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import img from "../../assets/Cart/Category Banner (1).png";
import Banner from "../../components/Banner/Banner";
import CategoryPage from "../../components/CategoryCards/CategoryPage";
import { getCategories } from "../../redux/reducers/categories";

function Categories() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [currentCategory, setCurrentCategory] = useState(null);
  const categoriesList = useSelector((state) => state.categories.categories);
  const categories = categoriesList?.results;
  const loading = useSelector((state) => state.categories.loading);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    setCurrentCategory(categories?.[0]);
  }, [categories]);

  const skeleton = Array(9)
    .fill(0)
    .map(() => (
      <Skeleton variant="rounded" width={290} height={70} animation="wave" />
    ));

  return (
    <div className="container">
      <Banner title="Categories" img={img} />
      <div className="row d-flex justify-content-center rounded-1 p-0 my-5 ">
        <div className="col-lg-3">
          <div className="d-flex flex-column justify-content-evenly fw-bold align-items-center gap-3 p-0">
            {loading
              ? skeleton
              : categories?.map((category, index) => {
                  return (
                    <p
                      role="button"
                      className={`
                     ${activeTab === index}
                        ? " text-center py-4 rounded-2 w-100"
                        : "py-3 text-center w-100 rounded-2"`}
                      style={{
                        color: activeTab === index ? "#fff" : "gray",
                        backgroundColor:
                          activeTab === index ? "#01497c" : "#fff",
                        fontSize: "20px",
                      }}
                      onClick={() => {
                        setActiveTab(index);
                        setCurrentCategory(category);
                      }}
                      key={index}
                    >
                      {category?.title}
                    </p>
                  );
                })}
          </div>
        </div>
        <div className="col-lg-9">
          <CategoryPage loading={loading} data={currentCategory} />
        </div>
      </div>
    </div>
  );
}

export default Categories;
