import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../common";

export const getFinances = createAsyncThunk(
  "finances/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/finance/`, {
        headers: {
          ["Authorization"]: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createFinance = createAsyncThunk(
  "finances/posts",
  async (data, { rejectWithValue }) => {
    // console.log(data)
    try {
      const response = await axios.post(`${BASE_URL}/finance/`, data, {
        headers: {
          ["Authorization"]: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createFinanceNote = createAsyncThunk(
  "financesNote/posts",
  async (data, { rejectWithValue }) => {
    // console.log(data);
    const postForm = new FormData();
    data.file.map((file) => {
      postForm.append("file", file);
    });

    postForm.append("finance", data.finance);

    try {
      const response = await axios.post(`${BASE_URL}/finance/note/`, postForm, {
        headers: {
          "Content-Type": "multipart/form-data",
          ["Authorization"]: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateFinanceStatus = createAsyncThunk(
  "financeUpdate/patch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/finance/${data.id}/`, data, {
        headers: {
          ["Authorization"]: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  financeList: null,
  financeCreated: null,
  noteCreated: null,
  statusUpdated: null,
  error: false,
};

const financesSlice = createSlice({
  name: "finances",
  initialState,
  reducers: {},
  extraReducers: {
    //get orders
    [getFinances.pending]: (state, action) => {
      state.loading = true;
      state.financeList = null;
    },
    [getFinances.fulfilled]: (state, action) => {
      state.loading = false;
      state.financeList = action.payload;
    },
    [getFinances.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },

    //create order
    [createFinance.pending]: (state, action) => {
      state.loading = true;
      state.financeCreated = null;
    },
    [createFinance.fulfilled]: (state, action) => {
      state.loading = false;
      state.financeCreated = action.payload;
    },
    [createFinance.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },

    //create finance note
    [createFinanceNote.pending]: (state, action) => {
      state.loading = true;
      state.noteCreated = null;
    },
    [createFinanceNote.fulfilled]: (state, action) => {
      state.loading = false;
      state.noteCreated = action.payload;
    },
    [createFinanceNote.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },

     //create finance note
     [updateFinanceStatus.pending]: (state, action) => {
      state.loading = true;
      state.noteCreated = null;
    },
    [updateFinanceStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.statusUpdated = action.payload;
    },
    [updateFinanceStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export default financesSlice.reducer;
