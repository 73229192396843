import StarsRate from "../../../components/StarsRate/StarsRate";
import Volovo from "../../../assets/common/volvo.png";

const SupplierInfo = (props) => {
  const { company } = props;
  return (
    <div className="d-flex flex-lg-row flex-column align-items-lg-start align-items-center">
      <img
        style={{ objectFit: "cover" }}
        src={company?.business_image ? company?.business_image : Volovo}
        alt="volvo"
        className="volvo-pic rounded-circle"
      />
      <div className="w-100 p-2">
        <h4 className="supplier-title p-0 m-0">{company?.company_name}</h4>
        <div className="d-flex flex-lg-row flex-column justify-content-between align-items-center w-100">
          <p
            className="supplier-desc"
            style={{ maxWidth: "800px", width: "100%" }}
          >
            {company?.business_description}
          </p>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p className="p-0 m-0 rate-stars">{company?.ratings_avg}</p>
            <div>
              <StarsRate num={company?.ratings_avg} size={20} />
            </div>
            <p className="num-reviews">based on 158 reviews</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierInfo;
