import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import CustomSlider from "../../../layouts/CustomSlider/CustomSlider"
import { getProducts } from "../../../redux/reducers/products"

const NewArrivals = () => {
	const topSection = [
		{ title: "New Arrival" },
		{ button: "View More" },
		{ toLink: "/products" },
	]

	const dispatch = useDispatch()
	const products = useSelector((state) => state?.products.newestProducts)

	useEffect(() => {
		dispatch(getProducts({ sort: "newest" }))
	}, [dispatch])
	return (
		<div className='row my-md-5 my-3 px-3 justify-content-center'>
			<CustomSlider data={products?.results} topSection={topSection} />
		</div>
	)
}

export default NewArrivals
