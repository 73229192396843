import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import BackButton from "../../components/BackButton/BackButton"
import CustomSlider from "../../layouts/CustomSlider/CustomSlider"
import { getProduct, getProducts } from "../../redux/reducers/products"
import ProductDetailsTaps from "./ProductDetailsTabs/ProductDetailsTaps"
import ProductOverView from "./ProductOverView/ProductOverView"

const ProductPage = ({ wholesale }) => {
	const params = useParams()
	const dispatch = useDispatch()
	const product = useSelector((state) => state?.products?.product)
	const products = useSelector((state) => state?.products?.productsFiltered)
	const companyProducts = useSelector((state) => state?.products?.products)

	useEffect(() => {
		dispatch(getProduct(params?.slug))
	}, [])

	useEffect(() => {
		dispatch(getProducts({ category: product?.category?.[0]?.title }))
		dispatch(getProducts({ company: product?.company?.id }))
	}, [product])

	const relatedSection = [
		{ title: "Related Products" },
		{ button: "View More" },
	]
	const moreSection = [{ title: "More from Company" }, { button: "View More" }]

	return (
		<>
			<div className='container my-5'>
				<BackButton text={params?.title} backLink={-1} />
				<ProductOverView product={product} wholesale={wholesale} />
				<ProductDetailsTaps product={product} />
				<div className='mt-5'>
					<CustomSlider data={products?.results} topSection={relatedSection} />
				</div>
				<CustomSlider
					data={companyProducts?.results}
					topSection={moreSection}
				/>
			</div>
		</>
	)
}

export default ProductPage
