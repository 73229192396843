import Banner from "../../components/Banner/Banner"
import AdditionalSet from "./PersonalDetails/AdditionalSetting/AdditionalSet"
import PersonalInfo from "./PersonalDetails/PersonalInfo"
import ShippingAddress from "./PersonalDetails/ShippingAddress"
import "./Settings.css"
import img from "../../assets/common/CategoryBanner.png"
const Settings = () => {
	return (
		<div className='container my-2'>
			<Banner title={"Settings"} img={img} />
			<div className='row d-flex'>
				<div className='col-md-5'>
					<PersonalInfo />	
					<ShippingAddress />
				</div>
				<div className='offset-md-1 col-md-6'>
					<AdditionalSet />
				</div>
			</div>
		</div>
	)
}

export default Settings
