import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { STYLING } from "./styling"

const Header = (props) => {
	const { variation, item } = props

	const VAR1 = variation === 1
	return (
		<Row className='mb-4'>
			<Col
			// className='m-0 p-0'
			>
				<Row
				// className='m-0 p-0'
				>
					<Col
					// className='p-0 m-0'
					>
						<p
							className='m-0'
							style={{
								fontSize: "11px",
								color: STYLING.colors.grey,
								fontFamily: STYLING.font.primary,
							}}
						>
							{item?.created?.split(' ')?.[0]}
						</p>
					</Col>
					<Col
						// className='p-0 m-0'
						md={{ span: 12, order: VAR1 && "first" }}
					>
						<p
							className='m-0'
							style={{
								fontSize: STYLING.font.size.title,
								color: STYLING.colors.primary,
								fontWeight: STYLING.font.bold,
								fontFamily: STYLING.font.primary,
							}}
						>
							Order {item?.order_no}
						</p>
					</Col>
				</Row>
			</Col>
			{!VAR1 && (
				<Col className='d-flex align-items-middle justify-content-end'>
					<Link to={`/orders/order/${item?.id}`} style={{
						// pointerEvents: 'none',
					}}>
						<Button className='my-auto border-0' style={STYLING.buttonStyling}>
							More Details
						</Button>
					</Link>
				</Col>
			)}
		</Row>
	)
}

export default Header
