import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../common';

export const searchProducts = createAsyncThunk(
  'products/search',
  async (
    {
      find = '',
      expand = 'company,category,brand,reviews,skus,tag,gallery,images',
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.get(
        `${BASE_URL}/products/mix/?find=${find}&expand=${expand}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getProducts = createAsyncThunk(
  'products/get',
  async (
    {
      submit = null,
      sort = '',
      find = '',
      category = '',
      sub_category = '',
      brand = '',
      tag = '',
      price = false,
      company = '',
      pageNumber = '1',
    },
    { rejectWithValue }
  ) => {
    try {
      // console.log(category);

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const priceFilter = price
        ? `&min_price=${price[0]}&max_price=${price[1]}`
        : '';
      const url = `${BASE_URL}/products/mix/?sort=${sort}&find=${find}&category=${category}${priceFilter}&brand=${brand}&sub_category=${sub_category}&company=${company}&tag=${tag}&page=${pageNumber}&expand=company,category,brand,reviews,skus,tag,gallery,images`;
      // console.log(url)
      const response = await axios.get(url, config);
      // console.log(10, category);
      return {
        sort: sort,
        data: response.data,
        category: category,
        tag: tag,
        sub_category: sub_category,
      };
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getProduct = createAsyncThunk(
  'product/get',
  async (slug, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.get(
        `${BASE_URL}/products/slug/${slug}/?expand=company,category,brand,reviews,skus,gallery,tag,images`,
        config
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getProductbyCategory = createAsyncThunk(
  'productbycategory/get',
  async (title, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.get(
        `${BASE_URL}/products/mix/?category=${title}&expand=company,category,brand,reviews,skus,gallery,tag,images`,
        config
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getProductWholesale = createAsyncThunk(
  'productWholesale/get',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.get(
        `${BASE_URL}/products/wholesale/${id}/?expand=company,category,brand,reviews,skus,gallery,tag,images`,
        config
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  products: null,
  MPproducts: null,
  TPproducts: null,
  newestProducts: null,
  oldestProducts: null,
  heightPriceProducts: null,
  lowPriceProducts: null,
  productsFiltered: null,
  productsTagFiltered: null,
  product: null,
  productbycategory: null,
  productWholesale: null,
  error: false,
  search: {
    loading: false,
    find: '',
    results: [],
  },
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    search: (state, action) => {
      action.payload === null
        ? (state.search = { ...state.search, loading: false, results: [] })
        : (state.search.find = action.payload);
    },
    clearResults: (state) => {
      state.loading = false;
      state.products = [];
      state.product = null;
      state.err = null;
      state.filter = {
        category: '',
        subcategory: '',
        brand: '',
      };
    },
    clearFilter: (state) => {
      state.filter = {
        category: '',
        subcategory: '',
        brand: '',
      };
    },
    setFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value,
      };
    },
  },
  extraReducers: {
    //get products
    [getProducts.pending]: (state, action) => {
      state.loading = true;
      state.productsFiltered = undefined;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.sort) {
        switch (action.payload.sort) {
          case 'popular':
            state.MPproducts = action.payload.data;
            break;
          case 'top_rated':
            state.TPproducts = action.payload.data;
            break;
          case 'newest':
            state.newestProducts = action.payload.data;
            break;
          case 'oldest':
            state.oldestProducts = action.payload.data;
            break;
          case 'highest price':
            state.heightPriceProducts = action.payload.data;
            break;
          case 'lowest price':
            state.lowPriceProducts = action.payload.data;
            break;
          default:
            state.products = action.payload.data;
            break;
        }
      }
      if (action.payload.category !== '' && action.payload.sort == '') {
        state.productsFiltered = action.payload.data;
      } else if (action.payload.tag !== '' && action.payload.sort == '') {
        state.productsTagFiltered = action.payload.data;
      } else {
        state.products = action.payload.data;
      }
      action.payload.find &&
        (state.search.results = action.payload.data.results);
    },
    [getProducts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [searchProducts.pending]: (state, action) => {
      state.search.loading = true;
    },
    [searchProducts.fulfilled]: (state, action) => {
      state.search.loading = false;
      state.search.results = action.payload.results;
    },
    [searchProducts.rejected]: (state, action) => {
      state.search.loading = false;
      state.error = action.payload;
    },
    //get product by slug
    [getProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [getProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.product = action.payload;
    },
    [getProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getProductbyCategory.pending]: (state, action) => {
      state.loading = true;
    },
    [getProductbyCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.productbycategory = action.payload;
    },
    [getProductbyCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //get product wholesale by id
    [getProductWholesale.pending]: (state, action) => {
      state.loading = true;
    },
    [getProductWholesale.fulfilled]: (state, action) => {
      state.loading = false;
      state.productWholesale = action.payload;
    },
    [getProductWholesale.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { search, clearFilter, setFilter, clearResults } =
  productsSlice.actions;

export default productsSlice.reducer;
