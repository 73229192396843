import React from "react"
import { Outlet, Navigate, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

const PrivateRoutes = ({ allowed, children, ...rest }) => {
	const auth = useSelector((state) => state?.auth)
	const location = useLocation()
	// //console.log("private", allowed, eval(allowed[0]))
	// allowed?.map((allowed) => eval(allowed) && //console.log(12, eval(allowed)))
	return auth?.loading ? (
		<p className='display-5  w-100 my-5 py-5 text-center'>
			Checking Permissions....
		</p>
	) : auth?.isAuth ? (
		<Outlet />
	) : auth?.isAuth == false ? (
		<Navigate to='/login' state={{ from: location }} replace />
	) : (
		<p className='display-5  w-100 my-5 py-5 text-center'>
			Error: {auth?.error?.data}
			<br />
			<br />
			Please Try again Later
		</p>
	)
}

export default PrivateRoutes
