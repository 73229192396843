import React, { useState } from "react";
import img2 from "../../assets/Cart/Cart Product Image.png";
import img from "../../assets/Cart/Category Banner (1).png";
import { MdKeyboardArrowDown } from "react-icons/md";
import { GiDiscGolfBag } from "react-icons/gi";
import Banner from "../../components/Banner/Banner";
import { useDispatch, useSelector } from "react-redux";
import CartList from "./cartList/CartList";
import Summary from "./Summary/Summary";
import WholeSaleList from "./WholeSaleList/WholeSaleList";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FinanceModal from "../../components/FinanceModal/FinanceModal";
import { createFinance } from "../../redux/reducers/finances";
import CustomModal from "../../components/CustomModal/CustomModal";
import { clearCart } from "../../redux/reducers/cart";

function Cart() {
  //states
  const [open, setOpen] = useState(false);
  const [filesArray, setFilesArray] = useState(null);
  //navigate
  const navigate = useNavigate()

  //redux
  const dispatch = useDispatch();

  const productsCart = useSelector((state) => state.cart?.productsCart);
  const wholesaleCart = useSelector((state) => state.cart?.wholesaleCart);
  // console.log(filesArray);

  const HandleCreateFinance = () => {
    let skuList = productsCart.map((product) => {
      return {
        sku: product?.sku?.id,
        quantity: product?.quantity
      };
    });
    let wholsaleList = wholesaleCart.map((product) => {
      return product.id;
    });

    dispatch(
      createFinance({
        items: skuList,
        wholesale: wholsaleList,
        business_team: '1'
      })
    ).then((res) => {
      if (res.meta.requestStatus !== "rejected") {
        setOpen(false);
        dispatch(clearCart())
        navigate('/profile')
      }
    });
  };

  return (
    <div className="container">
      <Banner title="Cart" img={img} />
      <h1
        style={{
          width: "205px",
          height: " 48px",
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "48px",
        }}
      >
        Products
        <span
          style={{
            color: "rgba(42, 111, 152, 1)",
          }}
        >
          {" "}
          ({productsCart?.length})
        </span>
      </h1>

      <div className="row align-items-start">
        <div className="col-7">
          <CartList products={productsCart} />
          <h1
            style={{
              width: "250px",
              height: " 48px",
              fontWeight: 600,
              fontSize: "32px",
              lineHeight: "48px",
            }}
          >
            Wholesale
            <span
              style={{
                color: "rgba(42, 111, 152, 1)",
              }}
            >
              {" "}
              ({wholesaleCart?.length})
            </span>
          </h1>
          {wholesaleCart?.map((item) => {
            return <WholeSaleList item={item} />;
          })}
        </div>

        <div className="col-5 p-0 ps-4">
          <Summary />
          <div
            className="d-flex flex-column gap-3 mt-3"
            role="button"
            onClick={() =>
              productsCart?.length === 0 &&
              wholesaleCart?.length === 0 &&
              toast.error("Please add items to your cart first", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
              })
            }
          >
            <Link
              class="btn btn-primary py-3 d-flex justify-content-center align-items-center"
              type="submit"
              style={{
                backgroundColor: "#01497C",
                fontweight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                pointerEvents:
                  productsCart?.length > 0 || wholesaleCart?.length
                    ? "auto"
                    : "none",
              }}
              to="/checkout"
            >
              <p className="p-0 m-0"> Proceed to Checkout</p>
              <span className="ms-2">
                <GiDiscGolfBag />
              </span>
            </Link>
            <button
              class="btn bg-white py-3 d-flex justify-content-center align-items-center"
              type="submit"
              style={{
                color: "#01497C",
                fontweight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                border: "1px solid #01497C",
              }}
              onClick={() => {
                if (productsCart?.length != 0 || wholesaleCart?.length != 0) {
                  setOpen(true);
                }
              }}
            >
              Request Financial Aid
              <span className="ms-2">
                <GiDiscGolfBag />
              </span>
            </button>
            {open && (
              <CustomModal
                open={open}
                onClose={() => setOpen(false)}
                onDone={HandleCreateFinance}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;
