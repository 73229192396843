import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../common";

export const getCategories = createAsyncThunk(
  "categories/get",
  async (args, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${BASE_URL}/products/categories/`,
        config
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const searchCategories = createAsyncThunk(
  "categories/search",
  async (find, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${BASE_URL}/products/categories/?find=${find}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  categories: null,
  search: {
    loading: false,
    results: [],
  },
  error: false,
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: {
    //get products
    [getCategories.pending]: (state, action) => {
      state.loading = true;
    },
    [getCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.categories = action.payload;
    },
    [getCategories.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [searchCategories.pending]: (state, action) => {
      state.search.loading = true;
    },
    [searchCategories.fulfilled]: (state, action) => {
      state.search.loading = false;
      state.search.results = action.payload.results;
    },
    [searchCategories.rejected]: (state, action) => {
      state.search.loading = false;
      state.error = action.payload;
    },
  },
});

export const categoriesActions = categoriesSlice.actions;

export default categoriesSlice.reducer;
