import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BsHeart } from "react-icons/bs";
import { AiFillSetting } from "react-icons/ai";
import Accounts from "./Dropdowns/NavMiddle/Accounts";
import Search from "./Search/Search";
import { useDispatch, useSelector } from "react-redux";
import CustomBadge from "../../components/CustomBadge/CustomBadge";
import { getFavorites } from "../../redux/reducers/favorites";

const NavMiddle = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const user = useSelector((state) => state.auth.user);

  const productsCart = useSelector((state) => state.cart?.productsCart);
  const wholesaleCart = useSelector((state) => state.cart?.wholesaleCart);
  const favoriteList = useSelector((state) => state.favorites?.favoritesList?.results);

  useEffect(()=>{
    dispatch(getFavorites())
  },[])
  // console.log(user);
  return (
    <>
      {/* second navbar */}
      <div className="py-2 d-flex justify-content-between">
        <div className="d-flex w-100 align-items-center gap-3 justify-content-between">
          <Link className="navbar-brand text-white" to="">
            <p className="h1 logo-font">Surplus</p>
          </Link>
          <Search />
        </div>
        <div className="d-flex fsSmall align-items-center gap-5 ms-2 text-white">
          <Link className="nav-link d-flex align-items-center gap-2" to="/cart">
            <AiOutlineShoppingCart />
            Cart
            <CustomBadge number={productsCart?.length + wholesaleCart?.length}/>
          </Link>
          <Link className="nav-link d-flex align-items-center gap-2" to="/wishlist">
            <BsHeart />
            Wishlist
         {isAuth&& <CustomBadge number={favoriteList?.length}/>}
          </Link>
          <Link
            className="nav-link d-flex align-items-center gap-2 pe-4 border-end"
            to="/settings"
          >
            <AiFillSetting />
            Settings
          </Link>

          <Accounts />
        </div>
      </div>
    </>
  );
};

export default NavMiddle;
