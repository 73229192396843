import { useEffect, useState } from "react";
import { GiDiscGolfBag } from "react-icons/gi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Summary = ({ page }) => {
  //states
  const [productsTotal, setProductsTotal] = useState(0);
  const [wholesaleTotal, setWholesaleTotal] = useState(0);
  const productsCart = useSelector((state) => state.cart?.productsCart);
  const wholesaleCart = useSelector((state) => state.cart?.wholesaleCart);
  const financeProducts = useSelector((state)=> state.cart?.financeProducts);
  const financeWholesale = useSelector((state)=> state.cart?.financeWholesale);

  useEffect(() => {
    let total = 0;
    if(page == 'finance'){
      financeProducts?.map((item) => {
        total += Number(item?.total) * Number(item?.quantity);
      });
    }else{
      productsCart?.map((item) => {
        total += Number(item?.price) * Number(item?.quantity);
      });
    }
    setProductsTotal(total);
  }, [productsCart]);

  useEffect(() => {
    let total = 0;
    if(page == 'finance'){
      financeWholesale?.map((item) => {
        total += Number(item?.price);
      });
    }else{
      wholesaleCart?.map((item) => {
        total += Number(item?.price);
      });
    }
    setWholesaleTotal(total);
  }, [wholesaleCart]);

  // useEffect(() => {
  //   if (page == "finance") {
  //     let total = 0;
  //     productsCart?.map((item) => {
  //       total += Number(item?.price);
  //     });
  //     setProductsTotal(total);
  //   }
  // }, [page]);

  return (
    <>
      <div
        className="d-flex flex-column align-items-center p-4"
        style={{
          backgroundColor: "rgba(42, 111, 152, 0.15)",
          borderRadius: " 10px",
        }}
      >
        <p
          className="p-0 m-0"
          style={{
            width: "184px",
            height: "32px",
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "32px",
            color: "#012A4A",
          }}
        >
          Cart Summary
        </p>
        <div
          className="my-3"
          style={{
            width: "95%",
            height: "2px",
            backgroundColor: "#fff",
          }}
        ></div>
        <div className="row d-flex justify-content-center align-items-center">
          <div
            className="col-6 text-nowrap d-flex flex-column gap-3"
            style={{
              border: "none",
              color: "#1A1A1A",
              fontWeight: 500,
              fontSize: "22px",
              lineHeight: "33px",
            }}
          >
            <div>Products Total</div>
            <div>Wholesale Total</div>
            <div>Estimated Total</div>
          </div>
          <div
            className="col-6 text-nowrap text-end d-flex flex-column gap-3"
            style={{
              border: "none",
              color: "#6C757D",
              fontWeight: 500,
              fontSize: "22px",
              lineHeight: "33px",
            }}
          >
            <div>{productsTotal}</div>
            <div>{wholesaleTotal}</div>
            <div>{productsTotal + wholesaleTotal}</div>
          </div>
          <div
            className="my-3"
            style={{
              width: "95%",
              height: "2px",
              backgroundColor: "#fff",
            }}
          ></div>
          <div
            className="col-6 text-nowrap "
            style={{
              border: "none",
              color: "#1A1A1A",
              fontWeight: 600,
              fontSize: "22px",
              lineHeight: "33px",
            }}
          >
            <div>Estimated Total</div>
          </div>
          <div
            className="col-6 text-nowrap text-end"
            style={{
              border: "none",
              color: "#1A1A1A",
              fontWeight: 600,
              fontSize: "22px",
              lineHeight: "33px",
            }}
          >
            <div>{productsTotal + wholesaleTotal}</div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column gap-3 mt-5">
        {/* <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Enter promo code"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <button
            class="btn"
            id="basic-addon2"
            style={{
              backgroundColor: "#62A5C2",
              color: "#fff",
            }}
          >
            Apply
          </button>
        </div> */}

        {/* <button
          class="btn py-3"
          type="submit"
          style={{
            border: "1px solid #01497C",
            color: "#01497C",
          }}
        >
          Request Financial Aid
          <div></div>
        </button> */}
      </div>
    </>
  );
};

export default Summary;
