import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../common";

export const getOrders = createAsyncThunk(
  "orders/get",
  async ({find = '', sort = ''}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/company/purchase/orders/?expand=whole_sale${find && `&find=${find}`}${sort && `&sort=${sort}`}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOrderStatus = createAsyncThunk(
  "orderStatus/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/orders/order-status/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOrder = createAsyncThunk(
    "order/get",
    async (id, { rejectWithValue }) => {
      try {
        const response = await axios.get(
          `${BASE_URL}/company/purchase/orders/${id}/?expand=whole_sale,shipping_address`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );

export const createOrder = createAsyncThunk(
    "orders/post",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.post(
          `${BASE_URL}/company/purchase/orders/`, data ,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );


const initialState = {
  loading: false,
  ordersList: null,
  orderStatus: null,
  order: null,
  orderCreated: null,
  error: false,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: {
    //get orders
    [getOrders.pending]: (state, action) => {
        state.loading = true;
        state.ordersList = null;
    },
    [getOrders.fulfilled]: (state, action) => {
        state.loading = false;
        state.ordersList = action.payload;
    },
    [getOrders.rejected]: (state, action) => {
        state.loading = false;
        state.error = true;
    },

    //get order status
    [getOrderStatus.pending]: (state, action) => {
        state.loading = true;
    },
    [getOrderStatus.fulfilled]: (state, action) => {
        state.loading = false;
        state.orderStatus = action.payload;
    },
    [getOrderStatus.rejected]: (state, action) => {
        state.loading = false;
        state.error = true;
    },

    //get one order
    [getOrder.pending]: (state, action) => {
        state.loading = true;
        state.order = null
    },
    [getOrder.fulfilled]: (state, action) => {
        state.loading = false;
        state.order = action.payload;
    },
    [getOrder.rejected]: (state, action) => {
        state.loading = false;
        state.error = true;
    },

    //create order
    [createOrder.pending]: (state, action) => {
        state.loading = true;
    },
    [createOrder.fulfilled]: (state, action) => {
        state.loading = false;
        state.orderCreated = action.payload;
    },
    [createOrder.rejected]: (state, action) => {
        state.loading = false;
        state.error = true;
    },
  }
});

export default ordersSlice.reducer;
