import React from "react"
import { Col, Row } from "react-bootstrap"
import { STYLING } from "../Orders/OrdersCard/styling"

const Line = (props) => {
	const { horizontal } = props
	return (
		<Row className='my-1'>
			<Col md={!horizontal && 3} className='d-flex justify-content-center'>
				<div
					className='rounded'
					style={{
						width: horizontal ? "25px" : "1px",
						backgroundColor: STYLING.colors.primary,
						height: horizontal ? "1px" : "25px",
					}}
				/>
			</Col>
		</Row>
	)
}

export default Line
