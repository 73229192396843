const Variants = (props) => {
	const product = props.product
	const variants = [
		{
			title: "Model",
			options: [
				"Option 1",
				"Option 2",
				"Option 3",
				"Option 1",
				"Option 2",
				"Option 3",
				"Option 1",
				"Option 2",
				"Option 3",
				"Option 1",
				"Option 2",
				"Option 3",
			],
		},
		{
			title: "By Series",
			options: ["Option 1", "Option 2", "Option 3"],
		},
		{
			title: "By Brand",
			options: ["Option 1", "Option 2", "Option 3"],
		},
	]
	return (
		<div className='row justify-content-start justify-content-md-start align-items-center'>
			<p className='title-details py-4 m-0'>Variants of this Product</p>
			<div className='d-flex flex-column'>
				{product
					? product?.map((variant, index) => {
							return (
								<div key={index}>
									<button
										className='btn-variant text-light'
										style={{
											fontSize: 22,
										}}
									>
										{variant?.name}
									</button>
									<div className='py-4 d-flex flex-wrap gap-2'>
										{variant?.option.map((option, index) => {
											return (
												<button
													className='btn-variant text-light rounded-pill'
													style={{
														width: 140,
														height: 50,
														backgroundColor: "var(--lightBlue)",
													}}
													key={index}
												>
													{option?.name}
												</button>
											)
										})}
									</div>
								</div>
							)
					  })
					: "No Varients Available"}
			</div>
		</div>
	)
}

export default Variants
