import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: false,
  wholesaleCart: [],
  productsCart: [],
  financeProducts: [],
  financeWholesale: []
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      if (action.payload.type === "wholesale") {
          state.wholesaleCart.push({...action.payload, quantity: 1});
      } else {
          state.productsCart.push({...action.payload, quantity: 1});
      }
    },
    increaseQuantity: (state, action) => {
        const foundIndex = state.productsCart.findIndex(item => item.id === action.payload.id);
        if (foundIndex !== -1 && state.productsCart[foundIndex].quantity < action.payload.stock) {
          state.productsCart[foundIndex].quantity += 1;
        }
    },
    decreaseQuantity: (state, action) => {
        const foundIndex = state.productsCart.findIndex(item => item.id === action.payload.id);
        if (foundIndex !== -1) {
          if(state.productsCart[foundIndex].quantity === 1){
            state.productsCart.splice(foundIndex, 1);
          }else{
            state.productsCart[foundIndex].quantity -= 1;
          }
        }
    },
    removeFromCart: (state, action) => {
      if(action.payload.type === "wholesale"){
        const foundIndex = state.wholesaleCart.findIndex(item => item.id === action.payload.id);
        if (foundIndex !== -1) {
          state.wholesaleCart.splice(foundIndex, 1);
        }
      }else{
        const foundIndex = state.productsCart.findIndex(item => item.id === action.payload.id);
        if (foundIndex !== -1) {
          state.productsCart.splice(foundIndex, 1);
        }
      }
    },
    updateCart: (state, action) => {
      state.productsCart = action.payload.productsCart;
      state.wholesaleCart = action.payload.wholesaleCart;
    },
    updateFinanceCart: (state, action) => {
      state.financeProducts = action.payload.financeProducts;
      state.financeWholesale = action.payload.financeWholesale;
    },
    clearCart: (state, action) => {
      state.wholesaleCart = [];
      state.productsCart = [];
    }
  },
  extraReducers: {},
});

export const { addToCart, increaseQuantity, decreaseQuantity, removeFromCart, clearCart, updateCart, updateFinanceCart } = cartSlice.actions;
export default cartSlice.reducer;
