import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, tokenConfig } from '../common';

const token = localStorage.getItem('token')
  ? localStorage.getItem('token')
  : null;

export const sendEmail = createAsyncThunk(
  'auth/sendEmail',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.post(
        `${BASE_URL}/auth/users/reset_password/`,
        data,
        config
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resetpassword = createAsyncThunk(
  'auth/resetpassword',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.post(
        `${BASE_URL}/auth/users/reset_password_confirm/`,
        data,
        config
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  'auth/changepassword',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          ['Authorization']: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await axios.post(
        `${BASE_URL}/auth/users/set_password/`,
        data,
        config
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postLogin = createAsyncThunk(
  'auth/login',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const response = await axios.post(
        `${BASE_URL}/auth/login/`,
        data,
        config
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editUsers = createAsyncThunk(
  'auth/editUsers',
  async (data, { rejectWithValue }) => {
    const postForm = new FormData();
    postForm.append('phone_number', data.peronalData.employee.phone_number);
    postForm.append('name', data.peronalData.name);
    postForm.append('email', data.peronalData.email);
    postForm.append('image', data.peronalData.image);

    try {
      const response = await axios.patch(
        `${BASE_URL}/auth/users/${data.id}/`,
        postForm,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            ['Authorization']: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const postRegister = createAsyncThunk(
  'auth/register',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/auth/users/`, data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getLoadUser = createAsyncThunk(
  'auth/load',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/auth/user/get`,
        tokenConfig(getState)
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  user: null,
  data: null,
  token: token,
  error: false,
  isAuth: false,
  success: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action) => {
      localStorage.removeItem('token');
      state.loading = false;
      state.user = null;
      state.token = null;
      state.error = false;
      state.isAuth = false;
      state.success = false;
    },
  },
  extraReducers: {
    // 	LOAD
    [getLoadUser.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.isAuth = false;
    },
    [getLoadUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuth = true;
      state.error = false;
    },
    [getLoadUser.rejected]: (state, action) => {
      state.loading = false;
      state.isAuth = false;
      state.error = action.payload;
    },

    // resetpassword
    [resetpassword.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.isAuth = false;
    },
    [resetpassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.isAuth = true;
      state.error = false;
    },
    [resetpassword.rejected]: (state, action) => {
      state.loading = false;
      state.isAuth = false;
      state.error = {
        status: true,
        data: action.payload,
      };
    },
    // sentEmail
    [sendEmail.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.isAuth = false;
    },
    [sendEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.isAuth = true;
      state.error = false;
    },
    [sendEmail.rejected]: (state, action) => {
      state.loading = false;
      state.isAuth = false;
      state.error = {
        status: true,
        data: action.payload,
      };
    },

    // LOGIN
    [postLogin.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
      state.isAuth = false;
    },
    [postLogin.fulfilled]: (state, action) => {
      localStorage.setItem('token', action.payload.access);
      state.loading = false;
      state.user = action.payload;
      state.token = action.payload.access;
      state.success = true;
      state.isAuth = true;
      state.error = false;
    },
    [postLogin.rejected]: (state, action) => {
      state.loading = false;
      state.isAuth = false;
      state.error = {
        status: true,
        data: action.payload,
      };
    },

    [editUsers.pending]: (state, action) => {
      state.loading = true;
      state.isAuth = false;
    },
    [editUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuth = false;
      state.user = action.payload;
      state.regSucess = true;
      state.error = false;
      state.token = null;
    },
    [editUsers.rejected]: (state, action) => {
      state.loading = false;
      state.isAuth = false;
      state.error = {
        status: true,
        data: action,
      };
    },
    [postRegister.pending]: (state, action) => {
      state.loading = true;
      state.isAuth = false;
    },
    [postRegister.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuth = false;
      state.user = action.payload;
      state.regSucess = true;
      state.error = false;
      state.token = null;
    },
    [postRegister.rejected]: (state, action) => {
      state.loading = false;
      state.isAuth = false;
      state.error = {
        status: true,
        data: action,
      };
    },
  },
});

export const { logout, saveData } = authSlice.actions;

export default authSlice.reducer;
