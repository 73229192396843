import React from "react";
import { Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import ItemCard from "../../components/ItemCard/ItemCard";
import WholesaleCard from "../../components/WholesaleCard/WholesaleCard";
import img from "../../assets/Brands/Frame 75.png";
// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import required modules
import { Navigation } from "swiper"
import { Link } from "react-router-dom"
import { Skeleton } from "@mui/material"

const CustomSlider = (props) => {
	const { data, topSection, companies, company } = props
	return (
		<Row className='p-0 position-relative' style={{ width: "100%" }}>
			<div className='d-flex justify-content-between'>
				<p className='m-0 fs-4 fw-bold' style={{ color: "var(--blue)" }}>
					{topSection[0].title}
				</p>
				{data && (
					<Link
						to={topSection?.[2]?.toLink}
						className='btn py-1 px-4 rounded-pill text-white'
						style={{ backgroundColor: "var(--purple)" }}
					>
						{topSection[1].button}
					</Link>
				)}
			</div>
			{!company && data == undefined ? (
				<div className=' d-flex gap-4 mt-5 justify-content-center'>
					{Array(4)
						.fill(0)
						.map(() => (
							<li>
								<Skeleton variant='rectangular' width={290} height={342} />
							</li>
						))}
				</div>
			) : (
				<Swiper
					slidesPerView={1}
					spaceBetween={0}
					breakpoints={{
						768: {
							slidesPerView: 2,
						},
						1024: {
							slidesPerView: 3,
						},
						1360: {
							slidesPerView: 4,
						},
					}}
					navigation={true}
					modules={[Navigation]}
					className='mySwiper px-2'
				>
					{data?.length > 0 &&
						data?.map((data, index) =>
							data?.item?.length > 0 ? (
								<SwiperSlide key={index} className='py-2'>
									<WholesaleCard data={data} />
								</SwiperSlide>
							) : (
								<SwiperSlide key={index} className='py-2'>
									<ItemCard data={data} />
								</SwiperSlide>
							)
						)}
				</Swiper>
			)}
			{company && companies == undefined ? (
				<div className=' d-flex gap-4 mt-5 justify-content-center'>
					{Array(4)
						.fill(0)
						.map(() => (
							<li>
								<Skeleton variant='rectangular' width={290} height={342} />
							</li>
						))}
				</div>
			) : (
				<Swiper
					slidesPerView={1}
					spaceBetween={0}
					breakpoints={{
						768: {
							slidesPerView: 2,
						},
						1024: {
							slidesPerView: 4,
						},
						1360: {
							slidesPerView: 5,
						},
					}}
					navigation={true}
					modules={[Navigation]}
					className='mySwiper my-3'
				>
					{companies
						?.filter((comp) => comp?.company_name != "Admin")
						.map((company, index) => (
							<SwiperSlide key={index}>
								<Link
									className='text-decoration-none text-dark'
									to={`/supplierstore/${company?.slug}`}
								>
									<div className='my-3 px-4 d-flex flex-column justify-content-center align-items-center'>
										<img
											className='notSelectable'
											style={{ width: "192px", height: "160px" }}
											src={
												company?.business_image ? company?.business_image : img
											}
											alt='company'
										/>
										<p className='fw-bold'>{company?.company_name}</p>
									</div>
								</Link>
							</SwiperSlide>
						))}
				</Swiper>
			)}
		</Row>
	)
}

export default CustomSlider
