import React from "react"
import page404 from "../../assets/Page404/Page404.png"
const Page404 = () => {
	return (
		<div className='container d-flex flex-column justify-content-center align-items-center w-50'>
			<div>
				<img className='w-100' src={page404} alt='404 error' />
			</div>
			<div className='text-center'>
				<p className='fs-3 mb-1' style={{ color: "#5563A3" }}>
					Not Found Page ...
				</p>
				<p className='fs-5 text-secondary'>
					We're very sorry for inconvience. It looks like you're trying to
					access a page that either has been deleted or never been existed.
				</p>
				<br />
				<br />

				<a href='/'>
					<button className='btn btn-primary w-25 mb-5'>Go back home</button>
				</a>
			</div>
			{/* <Row className='justify-content-center'>
              <Col className='text-center'>
                  <p className='fw-bold fs-4 text-secondary text-center'>
                      We're very sorry for inconvience. It looks like you're trying to
                      access a page that either has been deleted or never been existed.
                  </p>
                  <Link
                      className='mt-3 btn text-white fs-4 fw-bold text-center'
                      style={{ backgroundColor: "#5563A3" }}
                      to='/'
                  >
                      Home
                  </Link>
              </Col>
          </Row> */}
		</div>
	)
}

export default Page404
