import ReviewCard from "./ReviewCard";

const ReviewPeople = () => {
    const reviews = [
        {
            name: "Shrouk Khaled",
            date: "2 days ago",
            headline: "Review Headline",
            rate: 4,
            review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            name: "Abanob Nagy",
            date: "2 days ago",
            headline: "Review Headline",
            rate: 3,
            review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            name: "Ahmed Negm",
            date: "2 days ago",
            headline: "Review Headline",
            rate: 5,
            review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            name: "Amr Saleh",
            date: "2 days ago",
            headline: "Review Headline",
            rate: 2,
            review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
    ]
  return (
    <div className="p-3 reviews-box" style={{
        height: 500,
        width: '100%',
        overflowY: "auto",
    }}>
      {
            reviews.map((review, index) => {
                return(
                    <ReviewCard key={index} review={review} />
                )  
            })
      }
    </div>
  );
};

export default ReviewPeople;
