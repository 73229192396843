import React from 'react';
import { BiLogOutCircle } from 'react-icons/bi';
import { CiUser } from 'react-icons/ci';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../../../../../redux/reducers/auth';
const Employee = () => {
  const dispatch = useDispatch();
  return (
    <>
      <li>
        <Link className="dropdown-item" to="/profile">
          <CiUser className="me-2" />
          Profile
        </Link>
      </li>
      <li className="dropdown-item" onClick={() => dispatch(logout())}>
        <BiLogOutCircle className="me-2" />
        Logout
      </li>
    </>
  );
};

export default Employee;
