import React from "react"
import { Link } from "react-router-dom"
import CurrencyDropdown from "./Dropdowns/NavTop/CurrencyDropdown"
import LanguageDropdown from "./Dropdowns/NavTop/LanguageDropdown"

const NavTop = () => {
	return (
		<>
			{/* first navbar */}
			<div className='py-2 d-flex justify-content-between'>
				<div className='d-flex align-items-center gap-4'>
					<CurrencyDropdown />
					<LanguageDropdown />
				</div>
				<div className='d-flex align-items-center justify-content-end text-white gap-3'>
					<Link className='nav-link ps-5 fsSmall' to=''>
						About Us
					</Link>
					<Link className='nav-link ps-5 fsSmall' to=''>
						Why Sell with Us
					</Link>
					<Link className='nav-link ps-5 fsSmall' to='/demand_product'>
						Demand Equipment
					</Link>
				</div>
			</div>
		</>
	)
}

export default NavTop
