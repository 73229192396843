import React from "react"
import { Link } from "react-router-dom"

const Guest = () => {
	return (
		<>
			<li className='py-1 px-2'>
				<Link className='dropdown-item' to='/login'>
					Sign In
				</Link>
			</li>
			<li className='py-1 px-2'>
				<Link className='dropdown-item' to='/registration'>
					Sign up as Employee
				</Link>
			</li>
			<li className='py-1 px-2'>
				<Link className='dropdown-item disabled'>Sign up as Company</Link>
			</li>
		</>
	)
}

export default Guest
