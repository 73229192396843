import { Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const CustomSnackbar = ({
  openSnack,
  onClose,
  message,
  vertical,
  horizontal,
  autoHideDuration,
}) => {
  return (
    <Snackbar
      open={openSnack}
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal,
      }}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      message={`${message}`}
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClose={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default CustomSnackbar;
