import {
	PersonOutline,
	LinkOutlined,
	NotificationsOutlined,
} from "@mui/icons-material"
import { useState } from "react"
import { Link } from "react-router-dom"
import CustomModal from "../../../../components/CustomModal/CustomModal"
import CustomSnackbar from "../../../../components/CustomSnackbar/CustomSnackbar"

const AdditionalCard = ({ item }) => {
	const [open, setOpen] = useState(false)
	const [disable, setDisable] = useState(false)
	const [openSnack, setOpenSnack] = useState(false)

	return (
		<div
			className='row'
			style={{
				borderBottom: "1px solid #E5E5E5",
			}}
		>
			<div className='col-12'>
				<p className='main-add-title p-0 my-2' style={{ color: "#024F87" }}>
					{item.title}
				</p>
				<div className='row d-flex justify-content-between align-items-center'>
					<p
						className='col-md-6'
						style={{
							fontWeight: 300,
							fontSize: 18,
							color: "#8A929A",
						}}
					>
						{item.description}
					</p>
					<button
						style={{
							maxWidth: "250px",
							width: "100%",
							backgroundColor:
								item.icon === "notification"
									? disable
										? "#7ACC66"
										: "#E07070"
									: "var(--mdBlue)",
						}}
						className='col-md-6 my-2 m-md-0 m-auto btn add-address-btn text-light gap-2 p-3 rounded-3 d-flex justify-content-center align-items-center'
						onClick={() => {
							if (item.icon === "notification") {
								disable ? setDisable(false) : setOpen(true)
							}
						}}
					>
						<Link
							to='/changepassword'
							style={{
								color: "#fff",
								textDecoration: "none",
							}}
						>
							{item.icon === "notification" && disable
								? "Enable Notifications"
								: item.title}
							{item.icon === "person" ? (
								<PersonOutline />
							) : item.icon === "password" ? (
								<LinkOutlined />
							) : item.icon === "notification" ? (
								<NotificationsOutlined />
							) : null}
						</Link>
					</button>
				</div>
				<CustomModal
					open={open}
					onClose={() => setOpen(false)}
					onDisable={() => {
						setDisable(true)
						setOpen(false)
						setOpenSnack(true)
					}}
				/>

				<CustomSnackbar
					openSnack={openSnack}
					onClose={() => setOpenSnack(false)}
					message='Notifications disabled'
					vertical='bottom'
					horizontal='right'
					autoHideDuration={2000}
				/>
			</div>
		</div>
	)
}

export default AdditionalCard
