import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import TopBrands from "../../../TopBrands/TopBrands";

const PageOverView = (props) => {
  const { company } = props;
  const loading = useSelector((state) => state?.companies.loading);

  return (
    <>
      <div className="p-5">
        <p className="overview-text p-0 m-0">OverView</p>
        <p>
          {loading ? (
            <li>
              <Skeleton variant="text" />
            </li>
          ) : company?.business_description ? (
            company?.business_description
          ) : (
            <p className="text-center h4 fw-bold text-muted">No Description</p>
          )}
        </p>
      </div>
      {/* <TopBrands /> */}
    </>
  );
};

export default PageOverView;
