import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { STYLING } from "./styling"

const OrderSummary = (props) => {
	const { variation, item } = props
	const VAR1 = variation === 1
	return (
		<>
			
			<Row >
				<Col md={7}>
					<p
						className='mb-0'
						style={{
							color: STYLING.colors.primary,
							fontSize: VAR1
								? STYLING.font.size.medium
								: STYLING.font.size.title,
							fontWeight: STYLING.font.bold,
						}}
					>
						Summary
					</p>
				</Col>
				<Col className='d-flex justify-content-end'>
					<p
						className='mb-0'
						style={{
							color: STYLING.colors.light,
							fontSize: VAR1
								? STYLING.font.size.medium
								: STYLING.font.size.title,
							fontWeight: STYLING.font.bold,
						}}
					>
						{item?.currency_code} {item?.order_price}
					</p>
				</Col>
				{/* <Col className='d-flex justify-content-end'>
					<Button
						variant='outline-warning'
						className=' mb-1'
						disabled
						style={
							VAR1
								? STYLING.readyMadeStyling.columnStatusV1
								: STYLING.readyMadeStyling.columnStatus
						}
					>
						{item?.status?.toUpperCase()}
					</Button>
				</Col> */}
			</Row>
		</>
	)
}

export default OrderSummary
