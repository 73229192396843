import { useState } from "react"
import PageOverView from "./PageOverview/PageOverview"
import supplier from "../../../../assets/common/supplier.png"
import { Skeleton } from "@mui/material"
const SupplierAboutUs = (props) => {
	const data = props
	const [active, setActive] = useState(0)
	const [page, setPage] = useState({})
	const companyPage = data?.companyPages?.results
	const allPages = companyPage?.slice()
	allPages?.unshift({ title: "Overview", body: "0" })
	const HandleSavePage = (index) => {
		setActive(index)
		setPage(allPages[index])
	}

	return (
		<>
			<div
				className='w-100 d-flex flex-lg-row flex-column p-3 gap-3'
				style={{
					height: "350px",
				}}
			>
				<div
					style={{
						backgroundColor: "#F8F9FA",
					}}
					className='p-3 rounded-3 col-lg-2'
				>
					<p
						className='m-0 fs-4 fw-bold'
						style={{
							color: "#012A4A",
						}}
					>
						Pages
					</p>
					<div className='p-2'>
						{!allPages ? (
							<div className=' d-flex flex-column gap-4 justify-content-center'>
								{Array(3)
									.fill(0)
									.map(() => (
										<li>
											<Skeleton variant='text' width={140} height={23.98} />
										</li>
									))}
							</div>
						) : (
							allPages?.map((item, index) => {
								return (
									<p
										className='p-0 my-2'
										style={{
											color: active === index ? "#01497C" : "#012A4A",
											cursor: "pointer",
											fontWeight: active === index ? "bold" : "normal",
										}}
										onClick={() => HandleSavePage(index)}
									>
										{item.title}
									</p>
								)
							})
						)}
					</div>
				</div>
				{active === 0 ? (
					<div className='col-lg-10'>
						{!data?.company?.business_image ? (
							<li>
								<Skeleton variant='rectangular' height={318} />
							</li>
						) : (
							<img
								style={{
									height: "350px",
									objectFit: "cover",
								}}
								src={
									data?.company?.business_image && data?.company?.business_image
								}
								alt='supplier-store'
								className='w-100 rounded-4'
							/>
						)}
					</div>
				) : (
					<div className='p-3'>
						<p className='page-name-text'>{page?.title}</p>
						<div dangerouslySetInnerHTML={{ __html: page?.body }} />
					</div>
				)}
			</div>

			{active === 0 && <PageOverView company={data.company} />}
		</>
	)
}

export default SupplierAboutUs
