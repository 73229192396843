import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function Pagination(props) {
  const { setPageNumber, count, pageNumber } = props;
  const [activePage, setActivePage] = useState(1);
  const [pagesList, setPagesList] = useState([]);

  useEffect(() => {
    setPageNumber(activePage);
  }, [activePage]);

  const num = () => {
    if (activePage == 1) {
      return 1;
    } else {
      return activePage;
    }
  };

  // console.log(num());

  const pages = Math.round(count / 10);
  // console.log(pages);

  useEffect(() => {
    let pagesArr = [];
    Array(pages)
      .fill(0)
      ?.map((page, index) => {
        pagesArr.push(index + 1);
      });
    setPagesList(pagesArr);
  }, [pages]);

  return (
    <div className="d-flex gap-1 my-4">
      <button
        className="py-2 px-3 border-0 text-dark rounded-1 "
        style={{ backgroundColor: '#DFE9F0' }}
        onClick={() => {
          setActivePage(activePage > 1 ? activePage - 1 : activePage);
        }}>
        {`<`}
      </button>
      {pagesList.slice(num() - 1, num() - 1 + 5).map((item, index) => {
        return (
          <button
            className={
              activePage == item
                ? 'py-2 px-3 border-0 text-dark rounded-1 pageActive'
                : 'py-2 px-3 border-0 text-dark rounded-1'
            }
            style={{ backgroundColor: '#DFE9F0' }}
            onClick={() => {
              setActivePage(item);
            }}>
            {item}
          </button>
        );
      })}
      <button
        className="py-2 px-3 border-0 text-dark rounded-1 "
        style={{ backgroundColor: '#DFE9F0' }}
        onClick={() => {
          setActivePage(activePage < 6 ? activePage + 1 : activePage);
        }}>
        {`>`}
      </button>
    </div>
  );
}
