import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { BsBoxSeam } from "react-icons/bs"
import { IoIosArrowDown } from "react-icons/io"
import Banner from "../../components/Banner/Banner"
import img from "../../assets/img/RequestProduct.png"
import { Card, Row } from "react-bootstrap"
function RequestProduct() {
	const [show, setShow] = useState(false)

	const handleButton = () => {
		setShow(!show)
	}

	useEffect(() => {
		setTimeout(() => {
			setShow(false)
		}, 5000)
	}, [show])

	return (
		<div className='container px'>
			<Banner title='Demand Product' img={img} />
			<Row>
				<Card style={{ boxShadow: "0px 5px 10px rgba(1, 73, 124, 0.15)" }}>
					<div className='request'>
						<div
							className='container my-5'
							style={{
								padding: "0 90px",
							}}
						>
							<div
								style={{
									fontWeight: 400,
									fontSize: "57px",
									lineHeight: "64px",
									color: "#012A4A",
								}}
							>
								<p className='font_A'>Can’t find a product?</p>
								<p className='font_A'>Request it below!</p>
							</div>

							<div className='row py-3'>
								<div className='col-12'>
									<FormControl
										variant='outlined'
										style={{
											width: "100%",
											margin: "15px 0",
										}}
									>
										<InputLabel htmlFor='outlined-adornment-email'>
											Product Name
										</InputLabel>
										<OutlinedInput
											required
											defaultValue=''
											id='outlined-adornment-email'
											type='email'
											endAdornment={
												<InputAdornment position='end'>
													<IconButton edge='end'>
														{/* <CiUser
                          style={{
							  color: "rgba(1, 73, 124, 1)",
							}}
                        /> */}
													</IconButton>
												</InputAdornment>
											}
											label='Product Name'
										/>
									</FormControl>
								</div>
								<div className='col-4'>
									<FormControl
										variant='outlined'
										style={{
											width: "350px",
											margin: "15px 0",
										}}
									>
										<InputLabel htmlFor='outlined-adornment-email'>
											Product Quantity
										</InputLabel>
										<OutlinedInput
											required
											defaultValue=''
											id='outlined-adornment-email'
											type='email'
											endAdornment={
												<InputAdornment position='end'>
													<IconButton edge='end'>
														{/* <CiUser
                          style={{
							  color: "rgba(1, 73, 124, 1)",
							}}
                        /> */}
													</IconButton>
												</InputAdornment>
											}
											label='Product Quantity Name'
										/>
									</FormControl>
								</div>
								<div className='col-8'>
									<FormControl
										variant='outlined'
										style={{
											width: "20%",
											margin: "15px 0",
										}}
									>
										<InputLabel htmlFor='outlined-adornment-email'>
											Unit
										</InputLabel>
										<OutlinedInput
											required
											defaultValue=''
											id='outlined-adornment-email'
											type='email'
											endAdornment={
												<InputAdornment position='end'>
													<IconButton edge='end'>
														<IoIosArrowDown
															style={{
																color: "rgba(1, 73, 124, 1)",
															}}
														/>
													</IconButton>
												</InputAdornment>
											}
											label='unit'
										/>
									</FormControl>
								</div>
								<div className='col-12'>
									<FormControl
										variant='outlined'
										style={{
											width: "100%",
											margin: "15px 0",
										}}
									>
										<InputLabel htmlFor='outlined-adornment-email'>
											Notes
										</InputLabel>
										<OutlinedInput
											required
											defaultValue=''
											id='outlined-adornment-email'
											type='textbox'
											endAdornment={
												<InputAdornment position='end'>
													<IconButton edge='end'>
														{/* <CiUser
                          style={{
							  color: "rgba(1, 73, 124, 1)",
							}}
                        /> */}
													</IconButton>
												</InputAdornment>
											}
											label='Notes'
										/>
									</FormControl>
								</div>
								<div className='col-6'>
									<button
										class='btn btn-primary px-5 py-3 mt-3'
										type='submit'
										style={{
											backgroundColor: "#01497C",
											fontweight: 600,
											fontSize: "16px",
											lineHeight: "24px",
										}}
										onClick={handleButton}
									>
										Request Product
										<span className='ms-2'>
											<BsBoxSeam />
										</span>
									</button>
								</div>
								<div className='col-6 d-flex justify-content-end'>
									{show && (
										<button
											class='btn btn-primary px-5 py-3 mt-3'
											type='submit'
											style={{
												backgroundColor: "rgba(42, 111, 152, 0.15)",
												color: "#000",
												fontweight: 600,
												fontSize: "16px",
												lineHeight: "24px",
											}}
										>
											Product Requested
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</Card>
			</Row>
		</div>
	)
}

export default RequestProduct
