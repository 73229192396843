import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../../components/Banner/Banner";
import TopRatedImage from "../../assets/img/TopRated.png";
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getFavorites } from "../../redux/reducers/favorites";
import ItemCard from "../../components/ItemCard/ItemCard";
import WholesaleCard from "../../components/WholesaleCard/WholesaleCard";

const Wishlist = () => {
    //redux
    const favoritesList = useSelector(state => state?.favorites?.favoritesList?.results)
    const dispatch = useDispatch()
    // console.log(favoritesList)

    useEffect(()=>{
        dispatch(getFavorites())
    },[])

  const skeleton = Array(9)
    .fill(0)
    .map((a, index) => (
      <Skeleton
        key={index}
        variant="rounded"
        width={290}
        height={342}
        animation="wave"
      />
    ));

  return (
    <Container className="px-md-5 px-3 ">
        <Banner
          title={'Wishlist'}
          img={TopRatedImage}
        />
      {/* <Row className="mt-5">
        <Col md={9}>
          {search && (
            <p className=" fs-2 fw-bold m-0" style={{ color: "var(--mdBlue)" }}>
              Search Results for{" "}
              <span style={{ color: "#B71C1C" }}>
                "{params?.search?.replace("-", " ")}"
              </span>
            </p>
          )}
          <p className="small p-0 m-0 text-muted fw-bold">
            Showing {count} results, across {Math.round(count / 10)} pages
          </p>
        </Col>
        {search && (
          <Dropdown placeholder="Sort By" sort={true} setSort={setSort} />
        )}
      </Row> */}
      <Row>
        <div className="col-lg-12">
          <div
            className="d-flex gap-3 justify-content-md-start justify-content-center flex-wrap  my-3"
            style={{ height: "fit-content" }}
          >
            {favoritesList?.map((item, index) =>
                  item?.wholesale ? (
                    <WholesaleCard key1={index} data={item?.wholesale} />
                  ) : (
                    <ItemCard key1={index} data={item?.product} />
                  )
                )}
          </div>

		{/* <Pagination count={count} setPageNumber={setPageNumber} pageNumber={pageNumber} /> */}
        </div>
      </Row>
    </Container>
  );
};

export default Wishlist;
