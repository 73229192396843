import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import FinancesList from "./FinancesList/FinancesList";

const MyFinances = () => {
  return (
    <div>
      {/* <TextField
        className="w-100"
        id="input-with-icon-textfield"
        placeholder="Search for a review"
        hiddenLabel
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        variant="standard"
      /> */}
      <div className="mt-4">
        <FinancesList/>
      </div>
    </div>
  );
};

export default MyFinances;
