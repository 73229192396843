import React from "react"
// import { ErrorBoundary } from "react-error-boundary"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
// import { toast, ToastContainer } from "react-toastify"
// import Falloutcomponent from "./layouts/Fallout/Fallout"
import { store } from "./redux/store"
import MarketplaceApp from "./core/MarketplaceApp"
import { ToastContainer } from "react-toastify"

const App = () => {
	// const errorHandler = (error, errorInfo) => {
	// 	toast.error("Sorry about that, please refresh the page", error, errorInfo)
	// }

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				limit={1}
				theme='light'
			/>

			<Router>
				<Provider store={store}>
					<MarketplaceApp />
				</Provider>
			</Router>
		</>
	)
}

export default App
