import ProductSpecific from "./Product-Specific/Product-Specific";

const Details = () => {
  const data = [
    {
      title: "Modal",
      body: "ESDRH0602",
    },
    {
      title: "Brand",
      body: "Ryzen",
    },
    {
      title: "Series",
      body: "Ryzen 9 7000 Series",
    },
    {
      title: "Colours",
      body: ['#CC3363', '#62A5C2', '#343A40'],
    },
    {
      title: "Serial No",
      body: "100-100000514WOF",
    },
  ];
  return (
    <div className="p-3">
      <div>
        <h3 className="title-details">Key Feautres</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales
          mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis
          turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
          sodales mattis nulla eu placerat.
        </p>
      </div>
      <div className="mt-4">
        <h3 className="title-details">Product Specifications</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales
          mattis nulla eu placerat. Phasellus in vestibulum purus, at venenatis
          turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
          sodales mattis nulla eu placerat.
        </p>
      </div>
      <div className="my-5">
        {
          data.map((item, index) => {
            return <ProductSpecific key={index} item={item}/>
          }
          )
        }
      </div>
    </div>
  );
};

export default Details;
