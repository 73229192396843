import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { GiDiscGolfBag } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ItemCard from "../../components/ItemCard/ItemCard";
import { addToCart, removeFromCart } from "../../redux/reducers/cart";
import { getProductWholesale } from "../../redux/reducers/products";

function Wholesale() {
  //states
  const [quantity, setQuantity] = useState(0);
  const [wholesale_name, setwholesale_name] = useState(null);

  //params
  const { id } = useParams();
  //dispaych
  const dispatch = useDispatch();
  const productWholesale = useSelector(
    (state) => state?.products?.productWholesale
  );
  const cart = useSelector((state) => state.cart?.wholesaleCart);

  useEffect(() => {
    id && dispatch(getProductWholesale(id));
  }, [id]);

  useEffect(() => {
    if (productWholesale) {
      setwholesale_name(productWholesale?.title);
    }
  }, [productWholesale]);
  // console.log(wholesale_name);

  useEffect(() => {
    const itemInCart = cart?.find(
      (cartItem) => cartItem?.id === productWholesale?.id
    );
    itemInCart ? setQuantity(itemInCart?.quantity) : setQuantity(0);
  }, [cart]);

  //functions
  const HandleAddToCart = () => {
    dispatch(
      addToCart({
        id: productWholesale?.id,
        title: productWholesale?.title,
        slug: productWholesale?.slug,
        price: productWholesale?.price,
        company: productWholesale?.company,
        items: productWholesale?.item,
        type: "wholesale",
      })
    );
  };

  const HandleRemoveFromCart = () => {
    dispatch(
      removeFromCart({
        id: productWholesale?.id,
        type: "wholesale",
      })
    );
  };

  return (
    <div className="container">
      <div
        className="py-4"
        style={{
          padding: "0 90px",
        }}
      >
        <div className="row d-flex flex-column gap-1">
          <div
            className="col-md-8"
            style={{
              fontWeight: 600,
              fontSize: "33.338px",
              color: "#012A4A",
            }}
          >
            {productWholesale?.title}
          </div>
          <div
            className="col-md-8"
            style={{
              fontWeight: 500,
              fontSize: "13.3352px",
              lineHeight: "28px",
              letterSpacing: "0.125018px",
              color: "#2A6F98",
            }}
          >
            {productWholesale?.company}
          </div>
          <div
            className="col-md-12"
            style={{
              fontWeight: 400,
              fontSize: "13.3352px",
              lineHeight: "20px",
              letterSpacing: "0.416725px",
              color: "#1A1A1A",
            }}
          >
            {productWholesale?.description
              ? productWholesale?.description
              : "no description"}
          </div>
        </div>
        {quantity <= 0 ? (
          <button
            class="btn btn-primary py-3 my-4"
            type="submit"
            style={{
              backgroundColor: "#01497C",
              fontweight: 600,
              fontSize: "16px",
              lineHeight: "24px",
              padding: "0 100px",
            }}
            onClick={HandleAddToCart}
          >
            Add to cart
            <span className="ms-2">
              <GiDiscGolfBag />
            </span>
          </button>
        ) : (
          <ButtonGroup aria-label="Basic example" className="mt-3">
            <Button variant="success" disabled>
              Added
            </Button>
            <Button variant="danger" onClick={HandleRemoveFromCart}>
              Remove
            </Button>
          </ButtonGroup>
        )}
      </div>
      <div className="cart d-flex gap-3 justify-content-center flex-column">
        <p
          className="p-0 m-2"
          style={{
            fontweight: 500,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#6C757D",
          }}
        >
          Showing {productWholesale?.item?.length} products
        </p>
        <div className="d-flex gap-3 flex-wrap mb-5 justify-content-start">
          {productWholesale?.item.slice(0, 6).map((item) => (
            <ItemCard data={item} wholesale_name={wholesale_name && wholesale_name} />
          ))}{" "}
        </div>
      </div>
    </div>
  );
}

export default Wholesale;
