import CustomSlider from "../../../layouts/CustomSlider/CustomSlider"
import img2 from "../../../assets/img/Frame 175.png"
import img3 from "../../../assets/img/Frame 75.png"
import img4 from "../../../assets/img/Frame00.png"
import img5 from "../../../assets/img/Frame1 75.png"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { getCompanies } from "../../../redux/reducers/companies"

const TopCompanies = () => {
	const topSection = [{ title: "Top Companies" }, { button: "View More" }]

	//redux
	const dispatch = useDispatch()
	const companies = useSelector((state) => state.companies.allCompanies)

	useEffect(() => {
		dispatch(getCompanies())
	}, [dispatch])

	return (
		<div className='row my-md-5 px-3 justify-content-center'>
			<CustomSlider
				companies={companies?.results}
				topSection={topSection}
				company={true}
			/>
		</div>
	)
}

export default TopCompanies
