import { BsBoxSeam } from "react-icons/bs";
import { FiFileText } from "react-icons/fi";
import AllStages from "../../../../../components/Orders/TrackOrder/AllStages";
import { Row } from "react-bootstrap";
import FinanceModal from "../../../../../components/FinanceModal/FinanceModal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createFinanceNote, getFinances } from "../../../../../redux/reducers/finances";
import { useNavigate } from "react-router";
import { updateFinanceCart } from "../../../../../redux/reducers/cart";

const FinanceCard = ({ finance }) => {
  //states
  const [open, setOpen] = useState(false);
  const [filesArray, setFilesArray] = useState(null);
  //redux
  const dispatch = useDispatch()
  //navigate
  const navigate = useNavigate()

  // Required_files
  const statusText =
    finance?.status == "Required_files" 
      ? "Upload Files"
      : finance?.status == "Accepted"
      ? "Order Now"
      : finance?.status == "Done"
      ? "OREDERED"
      : "";
  const statusNum =
    finance?.status == "Requested"
      ? 1
      : finance?.status == "Required_files"
      ? 2
      : finance?.status == "In_consideration"
      ? 3
      : finance?.status == "Accepted"
      ? 4
      : finance?.status == "Rejected"
      ? 4
      : finance?.status == "Done"
      ? 4
      : "";

  const HandleCreateFinancenNote = () => {
    dispatch(createFinanceNote({
      file: filesArray,
      finance: finance?.id
    })).then((res)=>{
      setOpen(false)
      dispatch(getFinances())
    })
  }

  const HandleOrderNow = () => {
    // console.log(finance)
    dispatch(updateFinanceCart({
      financeProducts: finance?.items,
      financeWholesale: finance?.wholesale
    }))
    navigate(`/finance/checkout/${finance?.id}`)
  }

  const HandleStatusFunctions = () => {
    if( statusText === 'Upload Files')
    {
      setOpen(true)
    }else if(statusText === 'Order Now'){
      HandleOrderNow()
    }
  }

  return (
    <div className="my-4">
      <div className="d-flex justify-content-between align-items-center">
        <p
          style={{
            color: "#01497C",
            fontWeight: "bold",
            fontSize: 18,
            padding: 0,
            margin: 0,
          }}
        >
          Finance request on “
          <span
            style={{
              textDecoration: "underline",
              color: "var(--lightBlue)",
            }}
          >
            {finance?.finance_no}
          </span>
          ”
        </p>
        {statusText && (
          <button
            className="btn upload-btn rounded-3 px-3 d-flex justify-content-center align-items-center gap-2"
            style={{
              backgroundColor: statusNum === 1 ? "#fff" : "#01497C",
              color: statusNum === 1 ? "#2A6F98" : "#fff",
              width: 150,
            }}
            onClick={HandleStatusFunctions}
            disabled={statusText == 'OREDERED'}
          >
            {/* {finance?.type === "request" ? "Upload Files" : "order Now"}
					{finance?.type === "request" ? <FiFileText /> : <BsBoxSeam />} */}
            {statusText}
          </button>
        )}
      </div>
      <div className="my-3">
        {/* <CustomStepper num={finance.number}/> */}
        <Row className="my-5 shadow p-4">
          <AllStages
            type="finance"
            current={statusNum}
            horizontal={true}
            status={finance?.status == "Rejected" ? "Rejected" : finance?.status == 'Done' ? 'Done' : "Accepted"}
          />
        </Row>
      </div>
      {open && (
        <FinanceModal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          onDone={HandleCreateFinancenNote}
          setFilesArray={setFilesArray}
          finance={finance}
        />
      )}
    </div>
  );
};

export default FinanceCard;
