import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

//onClose is a function that will be called when the modal is closed
//open is a boolean that will be true when the modal is open
const CustomModal = ({ onClose, open, onDone }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent className="px-5">
          <DialogContentText
            id="alert-dialog-description"
            style={{
              fontWeight: "bold",
              fontSize: 25,
            }}
            className="text-center"
          >
            {/* <span style={{ color: "#01497C" }}>
              Are you sure you <br /> want to
            </span>{" "}
            <span className="text-success">do this </span>? */}
            <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Odio accusantium placeat voluptatum, ab quaerat tenetur. Error ad nesciunt tempora quas cupiditate velit eius, corrupti voluptatem obcaecati, totam deserunt. Accusantium, animi.</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center align-items-center pb-5">
          <Button
            onClick={onClose}
            style={{
              backgroundColor: "#9B9FA3",
              color: "#fff",
              width: 100
            }}
            className="p-2 px-3 rounded-3"
          >
            Cancel
          </Button>
          <Button
            onClick={onDone}
            style={{
              backgroundColor: "#7acc66",
              color: "#fff",
              width: 100
            }}
            className="p-2 px-3 rounded-3"
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomModal;
