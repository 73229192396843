import React, { useEffect } from "react";
import img from "../../assets/Cart/Category Banner (1).png";
import brandImg from "../../assets/Brands/Frame 75.png";
import SearchBar from "../../components/SearchBar/SearchBar";
import Banner from "../../components/Banner/Banner";
import Dropdown from "../../components/Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getbrands } from "../../redux/reducers/brands";
import { Link } from "react-router-dom";
import slugify from "react-slugify";

function Brands() {
  const dispatch = useDispatch();

  const brands = useSelector((state) => state?.brands?.brands?.results);
  useEffect(() => {
    dispatch(getbrands());
  }, []);

  return (
    <>
      <div className="container">
        <Banner title="Brands" img={img} />
        <div className=" d-flex gap-md-5 gap-3 justify-content-between">
          <div
            className="search "
            style={{
              width: "100%",
            }}
          >
            <SearchBar />
          </div>
          <div
            className="sort "
            style={{
              maxWidth: "275px",
              width: "100%",
            }}
          >
            <Dropdown placeholder="Sort By" full={true} sort={true} />
          </div>
        </div>
        <div className="row gap-md-3 d-flex justify-content-between py-3">
          {brands?.map((item) => (
            <div className="col-md-2 col-6">
              <Link
                to={`/brand/${slugify(item?.title.toLowerCase())}`}
                className="d-flex flex-column justify-content-center align-items-center text-decoration-none"
              >
                <img
                  src={item?.image ? item?.image : brandImg}
                  alt=""
                  className="w-100 rounded-5"
                />
                <p
                  className="text-center mt-3"
                  style={{
                    width: "122px",
                    fontWeight: 600,
                    fontSize: "22px",
                    lineHeight: "33px",
                    color: "#012A4A",
                  }}
                >
                  {item?.title}
                </p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Brands;
