import React, { useEffect } from "react"
import { Row } from "react-bootstrap"
import defaultImg from "../../assets/img/ProductImage1.png"

const Banner = (props) => {
	const { title, img } = props
	// console.log(title)
	useEffect(() => {
		if (title === undefined)
			throw Error("component: Banner | Please enter Title")
	}, [title])
	return (
		<Row
			className='mt-5 mb-5 position-relative banner rounded'
			style={{ height: "280px" }}
		>
			<h2
				className='fs-1 text-white position-absolute'
				style={{
					width: "fit-content",
					bottom: "2rem",
					left: "3rem",
					fontFamily: "Anton",
					zIndex: "1",
					textShadow: "0px 4px 10px rgba(1, 73, 124, 0.1)",
					fontWeight: "60",
					transform: "scaleY(1.5)",
					letterSpacing: "0.1em",
					width: "fit-content",
				}}
			>
				{title}
			</h2>
			<img
				src={img ? img : defaultImg}
				className='p-0 w-100 h-100 rounded '
				style={{
					objectFit: "cover",
					display: "block",
					filter: "drop-shadow(0px 5px 5px rgba(1, 73, 124, 0.1))",
				}}
				alt='banner'
			/>
		</Row>
	)
}

export default Banner
